import { jwtDecode } from "jwt-decode";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "./Payment.css";
import config from "../APIs/Cred";
// import "primereact/resources/themes/lara-light-cyan/theme.css";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function Payment(props) {
  const [email, setEmail] = useState(props.email);
  const [Data, setData] = useState(props.userData);
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState("Online"); // Initial state is 'Online'

  const handleChange = (e) => {
    setStatus(e.target.value); // Update the status state when dropdown value changes
  };

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const d = await fetch(`${config.BACKENDPATH}/users/razorpay`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ amount: parseInt(props.fees) }),
    }).then((t) => {
      return t.json();
    });

    console.log(d);
    const options = {
      key: "rzp_test_W4XVUvi5imk2a8",
      currency: "INR",
      amount: d.fees*100,
      order_id: d.id,
      name: "Impresiot",
      description: "Thank you for nothing. Please give us some money",
      image: "",
      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);
      },
      prefill: {
        name: Data.fullName,
        email: Data.email,
        phone_number: Data.contact,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

    fetch(`${config.BACKENDPATH}/users/wComp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, id: props.id, wid: status }),
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data);
      })
      .catch((error) => {
        alert("Error saving details:", error);
      });
  }
  if ((!email || !Data) && props.mid==="0") {
    return <div>Loading</div>;
  } else {
    return (
      // <a
      //   className=""
      //   onClick={()=>setVisible(true)}
      //   target="_blank"
      //   rel="noopener noreferrer"
      // >
      //   Register
      // </a>
      <>{
        props.wid==="" && props.mid==="0"?<div onClick={() => setVisible(true)} style={{ backgroundColor: "transparent", border: "transparent",color:"white",fontWeight:"bolder" }}>Register</div>:
        <div style={{ backgroundColor: "transparent", border: "transparent",color:"white",fontWeight:"bolder" }}>Registered</div>
      
      }




        <Dialog
          header="Select preferred plan"
          visible={visible}
          style={{ width: "250px", color: "white" }}
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
        >
          <div>
            <select
              id="status"
              value={status}
              onChange={handleChange}
              style={{
                backgroundColor: "black",
                borderColor: "white",
                color: "white",
                padding: "10px",
                borderRadius: "10px",
                marginBottom: "20px",
              }}
            >
              {props.mode === "Online" ? (
                <>
                  <option value="Online">Online</option>
                  <option value="Online With Recording">
                    Online With Recording
                  </option>
                </>
              ) : (
                <>
                  <option value="Online">Online</option>{" "}
                  <option value="Offline">Offline</option>
                  <option value="Online With Recording">
                    Online With Recording
                  </option>
                </>
              )}{" "}
            </select>
              <div className="btn_razor" onClick={displayRazorpay}>
                Register
              </div>
            
          </div>
        </Dialog>
      </>
    );
  }
}
export default Payment;
