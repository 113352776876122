import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MeetingProvider,
  MeetingConsumer,
  useMeeting,
  useParticipant,
  Constants,
  usePubSub,
} from "@videosdk.live/react-sdk";
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { authToken, endRecordingFunc, startRecordingFunc } from "./API";
import ReactPlayer from "react-player";

import "./Host.css";
import Hls from "hls.js";
import logo from "../images/logo_t.png";
import { FaRegDotCircle } from "react-icons/fa";
import {
  CiVideoOn,
  CiVideoOff,
  CiMicrophoneOn,
  CiMicrophoneOff,
} from "react-icons/ci";
import { MdCallEnd } from "react-icons/md";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { PiConfettiBold, PiHandsClapping } from "react-icons/pi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { jwtDecode } from "jwt-decode";
import "primereact/resources/themes/lara-light-cyan/theme.css";

import { Dialog } from "primereact/dialog";
import { MdPersonSearch } from "react-icons/md";
import IMG from "../images/home_img.png";
import Loader from "../loader/Loader";




function ViewerView() {
  // States to store downstream url and current HLS state
  const playerRef = useRef(null);
  const menuRight = useRef(null);

  const { hlsUrls, hlsState } = useMeeting();
  const { publish } = usePubSub("NOTIFY_ATTENDEES");
  function sendEmoji(emoji) {
    // Dispatch custom event here so the local user can see their own emoji
    window.dispatchEvent(
      new CustomEvent("reaction_added", { detail: { emoji } })
    );
  }
  const toast = useRef(null);
  const items = [
    {
      items: [
        {
          label: "",
          icon: <PiConfettiBold className="react_icon_video" />,
          command: () => {
            sendEmoji("confetti");
            publish("confetti");
          },
        },
        {
          label: "",
          icon: <PiHandsClapping className="react_icon_video" />,
          command: () => {
            toast.current.show({
              severity: "warn",
              summary: "Delete",
              detail: "Data Deleted",
              life: 3000,
            });
          },
        },
      ],
    },
  ];


  useEffect(() => {
    if (hlsUrls.downstreamUrl && hlsState == "HLS_PLAYABLE") {
      if (Hls.isSupported()) {
        const hls = new Hls({
          maxLoadingDelay: 1, // max video loading delay used in automatic start level selection
          defaultAudioCodec: "mp4a.40.2", // default audio codec
          maxBufferLength: 0, // If buffer length is/become less than this value, a new fragment will be loaded
          maxMaxBufferLength: 1, // Hls.js will never exceed this value
          startLevel: 0, // Start playback at the lowest quality level
          startPosition: -1, // set -1 playback will start from intialtime = 0
          maxBufferHole: 0.001, // 'Maximum' inter-fragment buffer hole tolerance that hls.js can cope with when searching for the next fragment to load.
          highBufferWatchdogPeriod: 0, // if media element is expected to play and if currentTime has not moved for more than highBufferWatchdogPeriod and if there are more than maxBufferHole seconds buffered upfront, hls.js will jump buffer gaps, or try to nudge playhead to recover playback.
          nudgeOffset: 0.05, // In case playback continues to stall after first playhead nudging, currentTime will be nudged evenmore following nudgeOffset to try to restore playback. media.currentTime += (nb nudge retry -1)*nudgeOffset
          nudgeMaxRetry: 1, // Max nb of nudge retries before hls.js raise a fatal BUFFER_STALLED_ERROR
          maxFragLookUpTolerance: 0.1, // This tolerance factor is used during fragment lookup.
          liveSyncDurationCount: 1, // if set to 3, playback will start from fragment N-3, N being the last fragment of the live playlist
          abrEwmaFastLive: 1, // Fast bitrate Exponential moving average half-life, used to compute average bitrate for Live streams.
          abrEwmaSlowLive: 3, // Slow bitrate Exponential moving average half-life, used to compute average bitrate for Live streams.
          abrEwmaFastVoD: 1, // Fast bitrate Exponential moving average half-life, used to compute average bitrate for VoD streams
          abrEwmaSlowVoD: 3, // Slow bitrate Exponential moving average half-life, used to compute average bitrate for VoD streams
          maxStarvationDelay: 1, // ABR algorithm will always try to choose a quality level that should avoid rebuffering
        });

        let player = document.querySelector("#hlsPlayer");

        hls.loadSource(hlsUrls.downstreamUrl);
        hls.attachMedia(player);
      } else {
        if (typeof playerRef.current?.play === "function") {
          playerRef.current.src = hlsUrls.downstreamUrl;
          playerRef.current.play();
        }
      }
    }
  }, [hlsUrls, hlsState]);

  return (
    <div>
      {hlsState != "HLS_PLAYABLE" ? (
        <div className="viewer_window">
          <div className="hor_f" style={{ cursor: "pointer" }}>
            <div className="v_w_txt">
              ARE YOU READY FOR AN AMAZING EXPERIENCE?
            </div>
            <div className="v_w_join">Streaming not started yet!</div>
          </div>
        </div>
      ) : (
        hlsState == "HLS_PLAYABLE" && (
          <div>
            <video
              ref={playerRef}
              id="hlsPlayer"
              autoPlay={true}
              controls
              style={{ width: "100%", height: "100%" }}
              playsinline

              muted={true}
              playing
              onError={(err) => {
                console.log(err, "hls video error");
              }}
            ></video>
            {/* <div className="">
            <Toast ref={toast}></Toast>

            <Menu
              model={items}
              popup
              ref={menuRight}
              id="popup_menu_right"
              popupAlignment="right"
              style={{ fontSize: "20p", color: "white" }}
            />
            <Button
              label=""
              icon={<HiOutlineDotsVertical />}
              className="button_react_video"
              onClick={(event) => menuRight.current.toggle(event)}
              aria-controls="popup_menu_right"
              aria-haspopup
            />
          </div> */}
          </div>
        )
      )}
    </div>
  );
}

function Container(props) {
  const [joined, setJoined] = useState(null);
  const { join, localParticipant, changeMode } = useMeeting();

  const mMeeting = useMeeting({
    onMeetingJoined: () => {
      if (mMeetingRef.current.localParticipant.mode == "CONFERENCE") {
        mMeetingRef.current.localParticipant.pin();
      }
      setJoined("JOINED");
    },
    onMeetingLeft: () => {
      props.onMeetingLeave();
    },
    onParticipantModeChanged: (data) => {
      const localParticipant = mMeetingRef.current.localParticipant;
      if (data.participantId == localParticipant.id) {
        if (data.mode == Constants.modes.CONFERENCE) {
          localParticipant.pin();
        } else {
          localParticipant.unpin();
        }
      }
    },
    onError: (error) => {
      alert(error.message);
    },
    onHlsStateChanged: (data) => {
      console.log("HLS State Changed", data);
    },
  });
  const joinMeeting = () => {
    setJoined("JOINING");
    join();
  };

  const mMeetingRef = useRef(mMeeting);
  useEffect(() => {
    mMeetingRef.current = mMeeting;
  }, [mMeeting]);


  return (
    <div className="container">
      {joined && joined == "JOINED" ? (
        mMeeting.localParticipant.mode == Constants.modes.VIEWER ? (
          <>
            <ViewerView data={props.meetingId} name={props.name} />
          </>
        ) : null
      ) : joined && joined == "JOINING" ? (
        <div className="viewer_window">
          <div className="hor_f" style={{ cursor: "pointer" }}>
            <div className="v_w_txt">
              ARE YOU READY FOR AN AMAZING EXPERIENCE?
            </div>
            <div className="v_w_join">Joining you in</div>
          </div>
        </div>
      ) : (
        <div className="viewer_window">
          <div className="hor_f" style={{ cursor: "pointer" }}>
            <div className="v_w_txt">
              ARE YOU READY FOR AN AMAZING EXPERIENCE?
            </div>
            <button onClick={joinMeeting} className="v_w_join">
              Join
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function Viewer() {
  const location = useLocation();
  const [meetingId, setMeetingId] = useState(null);
  const [userData, setUserData] = useState(null);

  // Use the useParams hook directly within the functional component

  useEffect(() => {

    const meetingid = new URLSearchParams(location.search).get("meetingId");
    setMeetingId(meetingid);
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);

      fetchUserData(decodedToken.email);
    }
  }, [location.search]);



  const fetchUserData = (email) => {

    fetch(
      `https://impresiot-backend.onrender.com/users/findProfile?email=${email}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserData(() => data.user);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
    console.log(userData);
  };

  const [mode, setMode] = useState("VIEWER");

  const onMeetingLeave = () => {
    setMeetingId(null);
  };

  return authToken && meetingId ? (
    <MeetingProvider
      config={{
        meetingId,
        micEnabled: true,
        webcamEnabled: true,
        name: "PC",
        mode: mode,
      }}
      token={authToken}
    >
      <MeetingConsumer>
        {() => (
          <Container
            meetingId={meetingId}
            onMeetingLeave={onMeetingLeave}
            name={"PranavC"}
          />
        )}
      </MeetingConsumer>
    </MeetingProvider>
  ) : (
    <Loader />
  );
}
export default Viewer;
