import React, { useState } from "react";
import { BiHomeAlt2, BiLogOut } from "react-icons/bi";
import { CiCompass1 } from "react-icons/ci";
import { VscLibrary } from "react-icons/vsc";
import { FiSettings } from "react-icons/fi";
import "./Sidenavalt.css"; // Updated CSS file name
import { MdOutlinePersonSearch } from "react-icons/md";
import { CgCamera } from "react-icons/cg";

function Sidenavalt(props) {
  const [slct, setslct] = useState("1");

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  return (
    <div className="sidenavalt">
      <a
        href="#home"
        style={
          slct === "1"
            ? { textDecoration: "none", color: "white" }
            : { textDecoration: "none", color: "gray" }
        }
        onClick={() => setslct("1")}
      >
        <div className="sidenavalt_ele">
          <BiHomeAlt2 className="icon_sidenavalt" />
          {/* <div className="sidenavalt_txt">Home</div> */}
        </div>
      </a>
      <a
        href="#creators"
        style={
          slct === "2"
            ? { textDecoration: "none", color: "white" }
            : { textDecoration: "none", color: "gray" }
        }
        onClick={() => setslct("2")}
      >
        <div className="sidenavalt_ele">
          <MdOutlinePersonSearch className="icon_sidenavalt" />
          {/* <div className="sidenavalt_txt">Creators</div> */}
        </div>
      </a>
      <a
        href="#workshops"
        style={
          slct === "3"
            ? { textDecoration: "none", color: "white" }
            : { textDecoration: "none", color: "gray" }
        }
        onClick={() => setslct("3")}
      >
        <div className="sidenavalt_ele">
          <VscLibrary className="icon_sidenavalt" />
          {/* <div className="sidenavalt_txt">Workshops</div> */}
        </div>
      </a>
      <div
        className="sidenavalt_ele"
        style={{ color: "red", cursor: "pointer" }}
        onClick={handleLogout}
      >
        <BiLogOut className="icon_sidenavalt" />
        {/* <div className="sidenavalt_txt">Logout</div> */}
      </div>
      <a
        href="/profilecreate"
        style={{ textDecoration: "none",color:"white" }}
        onClick={() => setslct("4")}
        target="_blank"
      >
        <CgCamera className="icon_sidenavalt" />
        {/* <div className="sidenavalt_btn">Creator Studio</div> */}
      </a>
    </div>
  );
}

export default Sidenavalt;
