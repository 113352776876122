import React, { useState, useEffect } from "react";
import "./Create.css";
import Dashnav from "../../navbar/Dashnav";
import { jwtDecode } from "jwt-decode";

import Sidenavt from "../../navbar/Sidenavt";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
  AiFillYoutube,
} from "react-icons/ai";
import Bottomnav from "../../navbar/Bottomnav";
import { useWindowWidth } from "@react-hook/window-size";
import Loader from "../../loader/Loader";
import config from "../../APIs/Cred";

function Create() {
  const [image, setImage] = useState(null);
  const [userData, setUserData] = useState({
    fullName: "",
    username: "",
    bio: "",
    email: "",
    phoneNumber: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    image: "",
    approve: false,
  });
  console.log(userData);
  const onlyWidth = useWindowWidth();

  const [errors, setErrors] = useState({});
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    // Decode the token from local storage
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = decodeToken(token);

      // Set email from the decoded token
      setUserData({ ...userData, email: decodedToken.email });

      // Fetch user data based on the decoded token's email
      fetchUserData(decodedToken.email);
    }
  }, []);

  const decodeToken = (token) => {
    // Implement token decoding logic (e.g., using a library like jwt-decode)
    // Replace this with the actual token decoding logic
    console.log(jwtDecode(token));
    return jwtDecode(token);
  };

  const fetchUserData = (email) => {
    // Simulate fetching user data from the backend
    // Replace this with your actual API call to retrieve user data based on email
    fetch(`${config.BACKENDPATH}/users/findProfile?email=${email}&userEmail=`)
      .then((response) => response.json())
      .then((data) => {
        setUserData((prevData) => ({ ...prevData, ...data.creatorProfile }));
        setImage(data.creatorProfile.image);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const validateSocialLinks = () => {
    const socialLinksErrors = {};

    // Regular expressions to validate social links
    const urlRegex = /^(http(s)?:\/\/)?(www\.)?[a-z0-9.-]+\.[a-z]{2,5}\/?.*$/i;

    if (userData.facebook && !urlRegex.test(userData.facebook)) {
      socialLinksErrors.facebook = "Invalid Facebook link";
    }
    if (userData.twitter && !urlRegex.test(userData.twitter)) {
      socialLinksErrors.twitter = "Invalid Twitter link";
    }
    if (userData.instagram && !urlRegex.test(userData.instagram)) {
      socialLinksErrors.instagram = "Invalid Instagram link";
    }
    if (userData.youtube && !urlRegex.test(userData.youtube)) {
      socialLinksErrors.youtube = "Invalid YouTube link";
    }

    setErrors({ ...errors, ...socialLinksErrors });

    return Object.keys(socialLinksErrors).length === 0;
  };

  const handleSubmitE = () => {
    userData.image = image;

    // Validate and check for mandatory fields
    const validationErrors = {};
    if (!userData.fullName) {
      validationErrors.fullName = "Full Name is required";
    }
    if (!userData.image) {
      validationErrors.image = "Image is required";
    }
    if (!userData.username) {
      validationErrors.userName = "User Name is required";
    }
    if (!userData.bio) {
      validationErrors.bio = "Bio is required";
    }
    if (!userData.email) {
      validationErrors.email = "Email is required";
    }
    if (!userData.phoneNumber) {
      validationErrors.phoneNumber = "Phone Number is required";
    }

    setErrors(validationErrors);

    if (validateSocialLinks() && Object.keys(validationErrors).length === 0) {
      setIsDataFetched(true);
      // Send the updated user data to the backend
      // Replace this with your actual API call to update user data
      fetch(`${config.BACKENDPATH}/users/profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      })
        .then((response) => response.json())
        .then((data) => {
          setIsDataFetched(false);
          alert("User data updated successfully");
        })
        .catch((error) => {
          setIsDataFetched(false);
          alert("Error updating user data:", error);
        });
    } else if (!validateSocialLinks()) {
      setIsDataFetched(false);
      alert("You entered incorrect social links");
    } else {
      setIsDataFetched(false);
      alert("Please fill all the fields");
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = () => {
    setImage(null);
  };

  return (
    <div className="create">
      <Dashnav />
      <div className="dashboard_flex_hor">
        {onlyWidth >= 720 && <Sidenavt approve={userData.approve} />};
        {!userData || isDataFetched ? <Loader/>: <div className="edit">
          <div className="sch_workshop_txt">Edit profile</div>

          <div className="edit_card">
            <div className="edit_card_img">
              {image && (
                <div>
                  <img
                    src={image}
                    alt="Uploaded"
                    style={{ maxWidth: "300px" }}
                    className="uploaded_img"
                  />
                </div>
              )}
              {!image && <div className="uploaded_img">P</div>}
              <button onClick={handleImageDelete} className="delete-button">
                Delete Profile Photo
              </button>
              <label htmlFor="file-input" className="upload-button">
                Change Profile Photo
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                id="file-input"
                style={{ display: "none" }}
              />
            </div>
            <div className="inputs_edit">
              <div>
                <div>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={userData.fullName}
                    onChange={handleChange}
                    placeholder={
                      isDataFetched && userData.fullName.length > 0
                        ? ""
                        : "Enter your full name"
                    }
                  />
                  {errors.fullName && (
                    <p className="error-message">{errors.fullName}</p>
                  )}
                </div>

                <div>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={userData.username}
                    onChange={handleChange}
                    placeholder={
                      isDataFetched && userData.username > 0
                        ? ""
                        : "Enter your username"
                    }
                  />
                </div>

                <div>
                  <textarea
                    id="bio"
                    name="bio"
                    value={userData.bio}
                    onChange={handleChange}
                    placeholder={
                      isDataFetched && userData.bio > 0 ? "" : "Enter your bio"
                    }
                  />
                </div>

                <div>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={userData.email}
                    onChange={handleChange}
                    disabled
                  />
                  {errors.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>

                <div>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={userData.phoneNumber}
                    onChange={handleChange}
                    placeholder={
                      isDataFetched && userData.phoneNumber > 0
                        ? ""
                        : "Enter your phone number"
                    }
                  />
                  {errors.phoneNumber && (
                    <p className="error-message">{errors.phoneNumber}</p>
                  )}
                </div>
                <div
                  className="sch_workshop_txt"
                  style={{ marginBottom: "30px" }}
                >
                  Social Links
                </div>
                <div className="social_lnk">
                  <div className="social_link_item">
                    <AiFillFacebook className="icon_s_l" />
                    <input
                      type="text"
                      id="facebook"
                      name="facebook"
                      value={userData.facebook}
                      onChange={handleChange}
                      placeholder={
                        isDataFetched && userData.facebook > 0
                          ? ""
                          : "facebook.com/"
                      }
                    />
                    {errors.facebook && (
                      <p className="error-message">{errors.facebook}</p>
                    )}
                  </div>
                  <div className="social_link_item">
                    <AiFillTwitterSquare className="icon_s_l" />
                    <input
                      type="text"
                      id="twitter"
                      name="twitter"
                      value={userData.twitter}
                      onChange={handleChange}
                      placeholder={
                        isDataFetched && userData.twitter > 0
                          ? ""
                          : "twitter.com/"
                      }
                    />
                    {errors.twitter && (
                      <p className="error-message">{errors.twitter}</p>
                    )}
                  </div>
                  <div className="social_link_item">
                    <AiFillInstagram className="icon_s_l" />
                    <input
                      type="text"
                      id="instagram"
                      name="instagram"
                      value={userData.instagram}
                      onChange={handleChange}
                      placeholder={
                        isDataFetched && userData.instagram > 0
                          ? ""
                          : "instagram.com/"
                      }
                    />
                    {errors.instagram && (
                      <p className="error-message">{errors.instagram}</p>
                    )}
                  </div>
                  <div className="social_link_item">
                    <AiFillYoutube className="icon_s_l" />
                    <input
                      type="text"
                      id="youtube"
                      name="youtube"
                      value={userData.youtube}
                      onChange={handleChange}
                      placeholder={
                        isDataFetched && userData.youtube > 0
                          ? ""
                          : "youtube.com/"
                      }
                    />
                    {errors.youtube && (
                      <p className="error-message">{errors.youtube}</p>
                    )}
                  </div>
                </div>
                <div onClick={handleSubmitE} className="edit_save">
                  Save
                </div>
              </div>
            </div>
        </div>
          </div>}
        
      </div>
      {onlyWidth < 720 && <Bottomnav />};
    </div>
  );
}

export default Create;
