import React, { useEffect, useState } from "react";
import { BiHomeAlt2 } from "react-icons/bi";
import { CiCompass1 } from "react-icons/ci";
import { FiSettings } from "react-icons/fi";
import { IoCloudUploadSharp } from "react-icons/io5";
import { AiOutlineLock } from "react-icons/ai";
import { AiFillSchedule } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { jwtDecode } from "jwt-decode";

import "./Sidenav.css";
import { Link } from "react-router-dom";
function Sidenavt(props) {
  const [valid, setValid] = useState(props.approve);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);

    fetch("https://impresiot-backend.onrender.com/users/checkUserExists", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email:decodedToken.email }),
    })
      .then((response) => response.json())
      .then((data) => {
        setValid(data.exists);
      })
      .catch((error) => {
        alert("Error fetching details:", error);
      });
    }
  }, []);

  return (
    <div className="sidenav">
      <div
        className="sidenav_ele"
        style={{ cursor: valid ? "pointer" : "not-allowed" }}
      >
        <BiHomeAlt2 className="icon_sidenav" />
        {valid ? (
          <Link
            to={"/dashboard"}
            style={{ textDecoration: "none", color: "gray" }}
            target="_blank"
          >
            <div className="sidenav_txt">Dashboard </div>
          </Link>
        ) : (
          <div className="sidenav_txt">Dashboard </div>
        )}
        {!valid && <AiOutlineLock className="lock_icon" />}
      </div>
      <div
        className="sidenav_ele"
        style={{ cursor: valid ? "pointer" : "not-allowed" }}
      >
        <CiCompass1 className="icon_sidenav" />

        {valid ? (
          <Link
            to={"/analytics"}
            style={{ textDecoration: "none", color: "gray" }}
            target="_blank"

          >
            <div className="sidenav_txt">Analytics </div>
          </Link>
        ) : (
          <div className="sidenav_txt">Analytics </div>
        )}
        {!valid && <AiOutlineLock className="lock_icon" />}
      </div>
      <div
        className="sidenav_ele"
        style={{ cursor: valid ? "pointer" : "not-allowed" }}
      >
        <IoCloudUploadSharp className="icon_sidenav" />

        {valid ? (
          <Link
            to={"/scheduleWorkshop"}
            style={{ textDecoration: "none", color: "gray" }}
            target="_blank"

          >
            <div className="sidenav_txt">Uploads </div>
          </Link>
        ) : (
          <div className="sidenav_txt">Uploads </div>
        )}
        {!valid && <AiOutlineLock className="lock_icon" />}
      </div>
      <div
        className="sidenav_ele"
        style={{ cursor: valid ? "pointer" : "not-allowed" }}
      >
        <AiFillSchedule className="icon_sidenav" />
        {valid ? (
          <Link
            to={"/schedule"}
            style={{ textDecoration: "none", color: "gray" }}
            target="_blank"

          >
            <div className="sidenav_txt">Schedule </div>
          </Link>
        ) : (
          <div className="sidenav_txt">Schedule </div>
        )}{" "}
        {!valid && <AiOutlineLock className="lock_icon" />}
      </div>
      <div
        className="sidenav_ele"
        style={{ cursor: valid ? "pointer" : "not-allowed" }}
      >
        <CgProfile className="icon_sidenav" />
        {valid ? (
          <Link
            to={"/profile"}
            style={{ textDecoration: "none", color: "gray" }}
            target="_blank"

          >
            <div className="sidenav_txt">Profile </div>
          </Link>
        ) : (
          <div className="sidenav_txt">Profile </div>
        )}{" "}
      </div>

      <Link to={"/user"} style={{ textDecoration: "none" }}
      
      target="_blank"
      >
        <div className="sidenav_btn">Home</div>
      </Link>
    </div>
  );
}

export default Sidenavt;
