// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home{
    background-color: black;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
.boujee-text {
    --bg-size: 400%;
    --color-one: #FF3A3A;
    --color-two: #A282FF;
    
    font-family: sans-serif;
    font-size: clamp(4.5rem, 25vmin, 4.5rem);
    background: linear-gradient(
        90deg,
        var(--color-one),
        var(--color-two),
        var(--color-one)
      )
      0 0 / var(--bg-size) 100%;
    color: transparent;
    font-weight: bolder;
    background-clip: text;
    padding: 20px;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .boujee-text {
      animation: move-bg 8s linear infinite;
    }
    @keyframes move-bg {
      to {
        background-position: var(--bg-size) 0;
      }
    }
  }
  
  .sec_text_home{
    color: white;
    font-weight: bolder;
    font-size: 23px;
    margin-top: 15px;
  }
  .home_img{
    height: 35vw;
    margin-top: 30px;
  }

  `, "",{"version":3,"sources":["webpack://./src/components/home/Home.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,oBAAoB;IACpB,oBAAoB;;IAEpB,uBAAuB;IACvB,wCAAwC;IACxC;;;;;;+BAM2B;IAC3B,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;IACrB,aAAa;EACf;;EAEA;IACE;MACE,qCAAqC;IACvC;IACA;MACE;QACE,qCAAqC;MACvC;IACF;EACF;;EAEA;IACE,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,YAAY;IACZ,gBAAgB;EAClB","sourcesContent":[".home{\n    background-color: black;\n    height: 100vh;\n    width: 100vw;\n    overflow: hidden;\n}\n.boujee-text {\n    --bg-size: 400%;\n    --color-one: #FF3A3A;\n    --color-two: #A282FF;\n    \n    font-family: sans-serif;\n    font-size: clamp(4.5rem, 25vmin, 4.5rem);\n    background: linear-gradient(\n        90deg,\n        var(--color-one),\n        var(--color-two),\n        var(--color-one)\n      )\n      0 0 / var(--bg-size) 100%;\n    color: transparent;\n    font-weight: bolder;\n    background-clip: text;\n    padding: 20px;\n  }\n  \n  @media (prefers-reduced-motion: no-preference) {\n    .boujee-text {\n      animation: move-bg 8s linear infinite;\n    }\n    @keyframes move-bg {\n      to {\n        background-position: var(--bg-size) 0;\n      }\n    }\n  }\n  \n  .sec_text_home{\n    color: white;\n    font-weight: bolder;\n    font-size: 23px;\n    margin-top: 15px;\n  }\n  .home_img{\n    height: 35vw;\n    margin-top: 30px;\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
