import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MeetingProvider,
  MeetingConsumer,
  useMeeting,
  useParticipant,
  Constants,
  usePubSub,
} from "@videosdk.live/react-sdk";
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { authToken, endRecordingFunc, startRecordingFunc } from "./API";
import ReactPlayer from "react-player";

import "./Host.css";
import Hls from "hls.js";
import logo from "../images/logo_t.png";
import { FaRegDotCircle } from "react-icons/fa";
import {
  CiVideoOn,
  CiVideoOff,
  CiMicrophoneOn,
  CiMicrophoneOff,
} from "react-icons/ci";
import { MdCallEnd } from "react-icons/md";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { PiConfettiBold, PiHandsClapping } from "react-icons/pi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { jwtDecode } from "jwt-decode";

import { Dialog } from "primereact/dialog";
import { MdPersonSearch } from "react-icons/md";
import Loader from "../loader/Loader";

// function JoinScreen({ getMeetingAndToken, setMode }) {
//   const [meetingId, setMeetingId] = useState(null);
//   const onClick = async (mode) => {
//     setMode(mode);
//     await getMeetingAndToken(meetingId);
//   };
//   return (
//     <div className="container">
//       <button onClick={() => onClick("CONFERENCE")}>Create Meeting</button>
//       <br />
//       <br />
//       {" or "}
//       <br />
//       <br />
//       <input
//         type="text"
//         placeholder="Enter Meeting Id"
//         onChange={(e) => {
//           setMeetingId(e.target.value);
//         }}
//       />
//       <br />
//       <br />
//       <button onClick={() => onClick("CONFERENCE")}>Join as Host</button>
//       {" | "}
//       <button onClick={() => onClick("VIEWER")}>Join as Viewer</button>
//     </div>
//   );
// }
function ParticipantView(props) {
  //const router = useRouter();

  const { leave, toggleMic, toggleWebcam, stopHls, meetingId } = useMeeting();
  const micRef = useRef(null);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } =
    useParticipant(props.participantId);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);
  const handleLeaveAndClose = () => {
    leave();
    window.close();
  };
  return (
    <div key={props.participantId}>
      <audio ref={micRef} autoPlay muted={isLocal} />
      {webcamOn ? (
        <ReactPlayer
          //
          
          playsinline // very very imp prop
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          
          //
          url={videoStream}
          //
          width={"100vw"}
          height={"75vh"}
          onError={(err) => {
            console.log(err, "participant video error");
          }}
          className="video_play_sdk"
        />
      ) : (
        <div className={!props.iden ? "video_empty_sdk" : "video_empty_sdkI"}>
          {!props.iden ? "No Video Available" : props.name}
        </div>
      )}
      {!props.iden && (
        <div className="controls_video">
          {webcamOn ? (
            <CiVideoOn
              className="control_icon"
              onClick={() => toggleWebcam()}
            />
          ) : (
            <CiVideoOff
              className="control_icon"
              onClick={() => toggleWebcam()}
            />
          )}
          {micOn ? (
            <CiMicrophoneOn
              className="control_icon"
              onClick={() => toggleMic()}
            />
          ) : (
            <CiMicrophoneOff
              className="control_icon"
              onClick={() => toggleMic()}
            />
          )}
          <MdCallEnd
            className="control_icon control_end"
            onClick={handleLeaveAndClose}
          />
          <button
            onClick={async () => {
              const url = `https://api.videosdk.live/v2/hls/start`;
              const templateUrl = `https://lab.videosdk.live/react-custom-template-demo?meetingId=${meetingId}&token=${authToken}`;
              const options = {
                method: "POST",
                headers: {
                  Authorization: authToken,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  roomId: meetingId,
                  templateUrl: templateUrl,
                }),
              };

              const result = await fetch(url, options)
                .then((response) => response.json()) //result will have meeting id
                .catch((error) => console.error("error", error));
            }}
            className="button_hls"
          >
            Start Stream
          </button>
          <button onClick={() => stopHls()} className="button_hls">
            Stop Stream
          </button>
        </div>
      )}
    </div>
  );
}

function SpeakerView(props) {
  const { participants, hlsState } = useMeeting();
  const menuRight = useRef(null);
  const [recstate, setrecstate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { publish } = usePubSub("NOTIFY_ATTENDEES");
  function sendEmoji(emoji) {
    // Dispatch custom event here so the local user can see their own emoji
    window.dispatchEvent(
      new CustomEvent("reaction_added", { detail: { emoji } })
    );
  }
  const toast = useRef(null);

  useEffect(() => {
    console.log("a");
    const handleReactionAdded = (event) => {
      const { emoji } = event.detail;
      console.log(emoji);
      // Handle the emoji received and display it
      toast.current.show({
        severity: "info",
        summary: `Emoji: ${emoji}`,
      });
    };

    window.addEventListener("reaction_added", handleReactionAdded);

    
  }, []);


  const items = [
    {
      items: [
        {
          label: "",
          icon: <PiConfettiBold className="react_icon_video" />,
          command: () => {
            sendEmoji("confetti");
            publish("confetti");
          },
        },
        {
          label: "",
          icon: <PiHandsClapping className="react_icon_video" />,
          command: () => {
            sendEmoji("clap");
            publish("clap");
          },
        },
      ],
    },
  ];

  const [message, setMessage] = useState("");
  const [recSignal, setRecSignal] = useState(false);

  const { startRecording, stopRecording } = useMeeting();
  const [visible, setVisible] = useState(false);

  const handleStartRecording = async () => {
    setLoading(true);
    // Start Recording
    // If you don't have a `webhookUrl` or `awsDirPath`, you should pass null.
    const data = await startRecordingFunc(props.data);
    if (data) {
      setRecSignal(true);
      setLoading(false);
    }
  };

  const handleStopRecording = async () => {
    // Stop Recording
    const data = await endRecordingFunc(props.data);
    if (data) {
      setRecSignal(false);
    }
  };

  const speakers = [...participants.values()].filter((participant) => {
    return participant.mode == Constants.modes.CONFERENCE;
  });
  const viewers = [...participants.values()].filter((participant) => {
    return participant.mode == Constants.modes.VIEWER;
  });
  if (loading) {
    return <Loader />;
  } else {
    return (
      <div className="video_view">
        <div className="nav_style">
          <div className="logo_hls">
            <img src={logo} className="logo_video" />
            {hlsState === "HLS_STOPPED" ? (
              <FaRegDotCircle style={{ color: "red" }} />
            ) : (
              <FaRegDotCircle style={{ color: "green" }} />
            )}
            {/* <div style={{ marginLeft: "10px" }}>{hlsState}</div> */}
          </div>
          <div className="rec_flex">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "20px",
              }}
            >
              <MdPersonSearch />
              <div className="view_count_icon">({viewers.length})</div>
            </div>
            {/* <Toast ref={toast}></Toast> */}

            <div className="rec_style" style={{marginRight:"20px"}}>
              {!recSignal ? (
                <>
                  <FaRegDotCircle style={{ color: "red" }} />
                  <button
                    onClick={handleStartRecording}
                    className="rec_video_sdk"
                  >
                    Rec
                  </button>
                </>
              ) : (
                <>
                  <FaRegDotCircle style={{ color: "green" }} />
                  <button
                    onClick={handleStopRecording}
                    className="rec_video_sdk"
                  >
                    Rec
                  </button>
                </>
              )}
            </div>

            {/* <div className="">
              <Toast ref={toast}></Toast>

              <Menu
                model={items}
                popup
                ref={menuRight}
                id="popup_menu_right"
                popupAlignment="right"
                style={{ fontSize: "20p", color: "white" }}
              />
              <Button
                label=""
                icon={<HiOutlineDotsVertical />}
                className="button_react_video"
                onClick={(event) => menuRight.current.toggle(event)}
                aria-controls="popup_menu_right"
                aria-haspopup
              />
            </div> */}
          </div>
        </div>
        {speakers.map((participant) => (
          <ParticipantView
            participantId={participant.id}
            key={participant.id}
            iden={false}
          />
        ))}
      </div>
    );
  }
}

function ViewerList() {
  const { participants } = useMeeting();

  //Filtering only viewer participant
  const viewers = [...participants.values()].filter((participant) => {
    return participant.mode == Constants.modes.VIEWER;
  });

  return (
    <div>
      <p>Viewer list: </p>
      {viewers.map((participant) => {
        return <ViewerListItem participantId={participant.id} />;
      })}
    </div>
  );
}

function ViewerListItem({ participantId }) {
  const { displayName } = useParticipant(participantId);
  const { publish } = usePubSub(`CHANGE_MODE_${participantId}`);
  const onClickRequestJoinLiveStream = () => {
    publish("CONFERENCE");
  };
  return (
    <div>
      {displayName}{" "}
      <button
        onClick={() => {
          onClickRequestJoinLiveStream();
        }}
      >
        Request to join Livestream
      </button>
    </div>
  );
}


function Container(props) {
  const [joined, setJoined] = useState(null);
  const { join, localParticipant, changeMode } = useMeeting();

  const mMeeting = useMeeting({
    onMeetingJoined: () => {
      if (mMeetingRef.current.localParticipant.mode == "CONFERENCE") {
        mMeetingRef.current.localParticipant.pin();
      }
      setJoined("JOINED");
    },
    onMeetingLeft: () => {
      props.onMeetingLeave();
    },
    onParticipantModeChanged: (data) => {
      const localParticipant = mMeetingRef.current.localParticipant;
      if (data.participantId == localParticipant.id) {
        if (data.mode == Constants.modes.CONFERENCE) {
          localParticipant.pin();
        } else {
          localParticipant.unpin();
        }
      }
    },
    onError: (error) => {
      alert(error.message);
    },
    onHlsStateChanged: (data) => {
      console.log("HLS State Changed", data);
    },
  });
  const joinMeeting = () => {
    setJoined("JOINING");
    join();
  };

  const mMeetingRef = useRef(mMeeting);
  useEffect(() => {
    mMeetingRef.current = mMeeting;
  }, [mMeeting]);

  const [joinLivestreamRequest, setJoinLivestreamRequest] = useState();

  return (
    <div className="container">
      {joined && joined == "JOINED" ? (
        mMeeting.localParticipant.mode == Constants.modes.CONFERENCE ? (
          <>
            <SpeakerView data={props.meetingId} name={props.name} />
          </>
        ) : null
      ) : joined && joined == "JOINING" ? (
        <div className="viewer_window">
        <div className="hor_f" style={{ cursor: "pointer" }}>
          <div className="v_w_txt">
            ARE YOU READY WITH YOUR AMAZING SKILLS?
          </div>
          <div className="v_w_join">
            Taking you to the workshop...
          </div>{" "}
        </div>
      </div>      ) : (
        <div className="viewer_window">
          <div className="hor_f" style={{ cursor: "pointer" }}>
            <div className="v_w_txt">
              ARE YOU READY WITH YOUR AMAZING SKILLS?
            </div>
            <button onClick={joinMeeting} className="v_w_join">
              Start
            </button>{" "}
          </div>
        </div>
      )}
    </div>
  );
}

function Host() {
  const location = useLocation();
  const [meetingId, setMeetingId] = useState(null);
  const [userData, setUserData] = useState(null);

  // Use the useParams hook directly within the functional component

  useEffect(() => {
    // Set the meetingId using the idMeet from useParams

    const meetingid = new URLSearchParams(location.search).get("meetingId");
    setMeetingId(meetingid);
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);

      // Set email from the decoded token

      // Fetch user data based on the decoded token's email
      fetchUserData(decodedToken.email);
    }
  }, [location.search]);

  //   useEffect(() => {
  //     // Decode the token from local storage
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //       const decodedToken = jwtDecode(token);

  //       // Set email from the decoded token

  //       // Fetch user data based on the decoded token's email
  //       fetchUserData(decodedToken.email);
  //     }
  //   }, []);

  const fetchUserData = (email) => {
    // Simulate fetching user data from the backend
    // Replace this with your actual API call to retrieve user data based on email
    fetch(
      `https://impresiot-backend.onrender.com/users/findProfile?email=${email}&userEmail=${email}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserData(() => data.creatorProfile);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const [mode, setMode] = useState("CONFERENCE");

  const onMeetingLeave = () => {
    setMeetingId(null);
  };

  console.log(authToken, meetingId, userData);
  return authToken && meetingId && userData ? (
    <MeetingProvider
      config={{
        meetingId,
        micEnabled: true,
        webcamEnabled: true,
        name: userData.username,
        mode: mode,
      }}
      token={authToken}
    >
      <MeetingConsumer>
        {() => (
          <Container
            meetingId={meetingId}
            onMeetingLeave={onMeetingLeave}
            name={userData.fullName[0]}
          />
        )}
      </MeetingConsumer>
    </MeetingProvider>
  ) : (
    <Loader />
  );
}
export default Host;
