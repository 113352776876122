import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import Dashnav from "../../navbar/Dashnav";
import Sidenavt from "../../navbar/Sidenavt";
import "./Scheduleworkshop.css";
import { useWindowWidth } from "@react-hook/window-size";
import Bottomnav from "../../navbar/Bottomnav";
import Loader from "../../loader/Loader";
import config from "../../APIs/Cred";
function Scheduleworkshop() {
  const [image, setImage] = useState(null);
  const [load, setload] = useState(false);
  const [TOKEN, setTOKEN] = useState(null);
  const [status, setStatus] = useState("Online"); // Initial state is 'Online'
  const onlyWidth = useWindowWidth();


  const handleChange = (e) => {
    setStatus(e.target.value); // Update the status state when dropdown value changes
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    // Decode the token from local storage
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);

      // Set email from the decoded token

      // Fetch user data based on the decoded token's email
      setTOKEN(decodedToken.email);
    }
  }, []);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [fees, setFees] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the data to be sent to the backend
    const data = {
      image,
      title,
      description,
      date,
      time,
      fees,
      email: TOKEN,
      users: [],
      meetingId: "",
      mode:status,duration:endTime
    };

    try {
      setload(true);
      // Replace 'https://example.com/api' with your actual backend API endpoint
      const response = await fetch(
        `${config.BACKENDPATH}/users/workshop`,
        // "https://impresiot-backend.onrender.com/users/workshop",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      // Check if the request was successful
      if (response.ok) {
        // Optionally, you can reset the form fields after successful submission
        setTitle("");
        setDescription("");
        setDate("");
        setTime("");
        setendTime("");
        alert("Workshop scheduled successfully")
      } else {
        alert("Oops, try later");
      }
      setload(false);
    } catch (error) {
      alert("Oops, sorry try again");
    }
  };

  return (
    <div className="wShop">
      <Dashnav />
      <div className="dashboard_flex_hor">
        {onlyWidth >= 720 && <Sidenavt />};
        {load?<Loader/>:<div className="edit">
          <div className="sch_workshop_txt">Schedule workshop</div>
          <div className="edit_card" style={{ justifyContent: "flex-start" }}>
            <div className="edit_card_img">
              {image && (
                <div>
                  <img
                    src={image}
                    alt="Uploaded"
                    className="uploaded_thumbnail"
                  />
                </div>
              )}
              {!image && (
                <div className="uploaded_thumbnail" style={{ height: "200px" }}>
                  NO THUMBNAIL
                </div>
              )}

              <label
                htmlFor="file-input"
                className="upload-button"
                style={{ marginTop: "20px",fontWeight:"bold",cursor:"pointer" }}
              >
                Update Thumbnail
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                id="file-input"
                style={{ display: "none" }}
              />
              <form onSubmit={handleSubmit}>
                <label>
                  Title:
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="title_wShop"
                    placeholder="Title of the workshop"
                  />
                </label>
                <br />
                <label>
                  Description:
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="desc_wShop"
                    placeholder="Description of the workshop"
                  />
                </label>
                <br />
                <div style={{color:"white",width:"200px",border:"1px solid white",padding:"10px",textAlign:"left",width:"250px",marginTop:"20px",fontWeight:"bold"}}>Date of conduct & Start Time</div>

                <div className="date_time_wrap_Shop">
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="date_wShop"
                  />

                  <input
                    type="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    className="time_wShop"
                  />
                </div>
                <br />
                <div style={{color:"white",width:"180px",border:"1px solid white",padding:"10px",textAlign:"left",fontWeight:"bold"}}>Mode & End Time</div>
                <div className="date_time_wrap_Shop">
                  <select id="status" value={status} onChange={handleChange} className="date_wShop">
                    <option value="Online">Online</option>
                    <option value="Offline">Offline</option>
                    <option value="Offline">Online With Recording</option>
                  </select>
                  <input
                    type="time"
                    value={endTime}
                    onChange={(e) => setendTime(e.target.value)}
                    className="time_wShop"
                    
                  />
                </div>
                <br></br>
                <div className="rupee_btn_wShop_wrap">
                  <div className="rupee_value_wrap">
                    <div className="rupee_wShop">₹</div>
                    <input
                      type="text"
                      value={fees}
                      onChange={(e) => setFees(e.target.value)}
                      placeholder="0.00"
                      className="rupee_value"
                    />
                  </div>

                  <button type="submit" className="btn_sch_wShop" style={{cursor:"pointer"}}>
                    Schedule workshop
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>}
      </div>
      {onlyWidth < 720 && <Bottomnav />};
    </div>
  );
}

export default Scheduleworkshop;
