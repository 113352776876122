// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidenavalt_ele {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin: 20px;
  }
  
  .sidenavalt_txt {
    font-size: 15px;
    margin-left: 10px;
    font-weight: bolder;
  }
  
  .icon_sidenavalt {
    font-size: 20px;
  }
  
  .sidenavalt {
    background-color: #0d0d0d;
    width: 100%;
    height: auto;
    color: #494949;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    position: fixed; /* Fixed position */
    bottom: 0; /* Align to bottom */
    left: 0; /* Align to left */
    z-index: 1000; /* Ensure it stays on top */
    padding: 10px 0; /* Add some padding for better appearance */
  }
  
  
  .sidenavalt_btn {
    background-color: #ff3a3a;
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-weight: bolder;
    margin-top: 20px;
  }
  
  /* Add any additional styles as needed */
  `, "",{"version":3,"sources":["webpack://./src/components/navbar/Sidenavalt.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;IACf,eAAe,EAAE,mBAAmB;IACpC,SAAS,EAAE,oBAAoB;IAC/B,OAAO,EAAE,kBAAkB;IAC3B,aAAa,EAAE,2BAA2B;IAC1C,eAAe,EAAE,2CAA2C;EAC9D;;;EAGA;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA,wCAAwC","sourcesContent":[".sidenavalt_ele {\n    display: flex;\n    align-items: center;\n    margin-top: 20px;\n    margin: 20px;\n  }\n  \n  .sidenavalt_txt {\n    font-size: 15px;\n    margin-left: 10px;\n    font-weight: bolder;\n  }\n  \n  .icon_sidenavalt {\n    font-size: 20px;\n  }\n  \n  .sidenavalt {\n    background-color: #0d0d0d;\n    width: 100%;\n    height: auto;\n    color: #494949;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    align-items: center;\n    flex-wrap: wrap;\n    position: fixed; /* Fixed position */\n    bottom: 0; /* Align to bottom */\n    left: 0; /* Align to left */\n    z-index: 1000; /* Ensure it stays on top */\n    padding: 10px 0; /* Add some padding for better appearance */\n  }\n  \n  \n  .sidenavalt_btn {\n    background-color: #ff3a3a;\n    color: white;\n    padding: 10px;\n    border-radius: 10px;\n    font-weight: bolder;\n    margin-top: 20px;\n  }\n  \n  /* Add any additional styles as needed */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
