import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import logo from "../images/logo_t.png";
import validator from "validator";
import { baseUrl } from "../../constants/backendURL";

function Signin() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateEmail = () => {
    if (!validator.isEmail(formData.email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  // const baseURL = "https://impresiot-backend.onrender.com/";

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault(); // Prevent the default form submission behavior
    validateEmail();
    if (!emailError && formData.password) {
      // Send the form data to your authentication endpoint
      fetch(`${baseUrl}users/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.token) {
            // Store the JWT token in local storage if rememberMe is checked
            if (formData.rememberMe) {
              localStorage.setItem("token", data.token);
            }
            navigate("/user");
          } else {
            // Handle authentication errors here
            setLoading(false);
            alert("Server error, try again later");
          }
        })
        .catch((error) => {
          setLoading(false);
          alert(error);

          // Handle authentication errors here
          console.error("Error:", error);
        });
    }
  };

  return (
    <div className="signup_bg">
      <div className="signup" style={{ height: "50vh" }}>
        <img src={logo} className="signup_img" alt="Logo" />
        <div className="signup_txt1">Sign In</div>
        <form onSubmit={handleSubmit}>
          <div className="signup_ip_wrap">
            <div className="signup_ip">
              <div className="signup_ip_text">Email</div>
              <input
                type="email"
                name="email"
                className="signup_ip_box"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={validateEmail}
                required
              />
              <div className="error-message">{emailError}</div>
            </div>
          </div>
          <div className="signup_ip_wrap">
            <div className="signup_ip">
              <div className="signup_ip_text">Password</div>
              <input
                type="password"
                name="password"
                className="signup_ip_box"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="check_ip">
            <input
              type="checkbox"
              name="rememberMe"
              className="checkbox_signup"
              checked={formData.rememberMe}
              onChange={handleInputChange}
            />
            <div className="check_ip_txt">Remember Me</div>
          </div>
          <div
            className="signup_btn"
            type="submit"
            onClick={handleSubmit}
            style={{ cursor: "pointer" }}
          >
            Sign In
          </div>

          <div className="new_user">
            <div className="new_user_txt1">New User?</div>
            <a href="/signup" className="new_user_txt2">
              Create Account
            </a>
          </div>
          {loading && <div>check the info</div>}
        </form>
      </div>
    </div>
  );
}

export default Signin;
