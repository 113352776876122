import React, { useEffect, useState } from "react";
import "./Navbar.css";
import logo from "../images/logo_t.png";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useWindowWidth } from "@react-hook/window-size";
function Navbar() {
  const [valid, setValid] = useState(false);
  const width = useWindowWidth();

  useEffect(() => {
    // Decode the token from local storage
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);

      if (decodedToken.email) {
        setValid(() => true);
      }
    }
  }, []);
  return (
    <div className="navbar">
      <div className="navbar_l">
        <img src={logo} className="navbar_l_img" />
      </div>
      <div className="navbar_r">
        {width > 400 && <div className="navbar_ele_r">
          <a href="mailto:ayush@impresiot.com" style={{textDecoration:"none",color:"white"}}>

          Contact
          </a>
          
          </div>}
        {!valid ? (
          <>
            <Link to="/signin" style={{ textDecoration: "none" }}>
              <div className="navbar_ele_r">Sign in</div>
            </Link>
            <Link to="/signup" style={{ textDecoration: "none" }}>
              <div className="navbar_ele_btn">Sign up</div>
            </Link>
          </>
        ) : (
          <Link to="/user" style={{ textDecoration: "none" }}>
            <div className="navbar_ele_btn">Dashboard</div>
          </Link>
        
        )}
      </div>
    </div>
  );
}

export default Navbar;
