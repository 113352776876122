// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

}

.navbar_l_img{
    height: 80px;

}
.navbar_ele_r{
    font-size: 15px;
    color: #9E9E9E;
}

.navbar_ele_btn{
    background-color: red;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 15px;

}
.navbar_r{
    display: flex;
    width: 260px;
    justify-content: space-around;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;;AAEjB;;AAEA;IACI,YAAY;;AAEhB;AACA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,eAAe;;AAEnB;AACA;IACI,aAAa;IACb,YAAY;IACZ,6BAA6B;IAC7B,mBAAmB;AACvB","sourcesContent":[".navbar{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px;\n\n}\n\n.navbar_l_img{\n    height: 80px;\n\n}\n.navbar_ele_r{\n    font-size: 15px;\n    color: #9E9E9E;\n}\n\n.navbar_ele_btn{\n    background-color: red;\n    padding: 10px;\n    border-radius: 5px;\n    color: white;\n    font-size: 15px;\n\n}\n.navbar_r{\n    display: flex;\n    width: 260px;\n    justify-content: space-around;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
