import React, { useEffect, useState } from "react";
import Dashnav from "../../navbar/Dashnav";
import Sidenavt from "../../navbar/Sidenavt";
import "./Schedule.css";
import img from "../../images/scheduleimg.png";
import event from "../../images/events/event.png";
import { BsPlusSquare } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import { createMeeting, authToken } from "../../videosdk/API";
import { jwtDecode } from "jwt-decode";
import Countdown from "react-countdown";
import { useWindowWidth } from "@react-hook/window-size";
import Bottomnav from "../../navbar/Bottomnav";
import Loader from "../../loader/Loader";
import config from "../../APIs/Cred";

function Schedule() {
  const [meetingId, setMeetingId] = useState(null);
  const [TOKEN, setTOKEN] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [workshops, setWorkshops] = useState([]);
  const onlyWidth = useWindowWidth();

  // function JoinScreen({ getMeetingAndToken, setMode, eventId }) {
  //   const [meetingId, setMeetingId] = useState(null);

  //   //Set the mode of joining participant and set the meeting id or generate new one
  //   const onClick = async (mode) => {
  //     setLoading(true);
  //     setMode(mode);
  //     await getMeetingAndToken(meetingId, eventId);
  //   };
  //   return (
  //     <button onClick={() => onClick("CONFERENCE")} className="meetingBtn">
  //       Start
  //     </button>
  //   );
  // }

  function JoinScreen({ getMeetingAndToken, setMode, eventId, startTime }) {
    const [meetingId, setMeetingId] = useState(null);
    const [countdownComplete, setCountdownComplete] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(0);

    const onClick = async (mode) => {
      setLoading(true);
      setMode(mode);
      await getMeetingAndToken(meetingId, eventId);
    };

    useEffect(() => {
      const currentTime = new Date();
      const difference = startTime - currentTime;

      console.log(countdownComplete);
      // If the workshop has not started yet
      if (difference > 0) {

        setTimeRemaining(difference);
        const timerId = setInterval(() => {
          const updatedDifference = startTime - new Date();
          if (updatedDifference <= 0) {
            setCountdownComplete(true);
            clearInterval(timerId);
          } else {
            setTimeRemaining(updatedDifference);
          }
        }, 1000);
      } else {
        // If the workshop has already started
        setCountdownComplete(true);
      }
    }, [startTime]);

    return (
      <div>
        <button
          onClick={() => onClick("CONFERENCE")}
          className={`meetingBtn ${countdownComplete ? "" : "disabled"}`}
          disabled={!countdownComplete}
          style={
            !countdownComplete
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          {countdownComplete ? "Start" : `Disabled`}
        </button>
      </div>
    );
  }

  function formatTime(milliseconds) {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

    const formatNumber = (number) => (number < 10 ? `0${number}` : number);

    return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(
      seconds
    )}`;
  }

  const gettoken = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);

      // Set email from the decoded token

      // Fetch user data based on the decoded token's email
      setTOKEN(decodedToken.email);

      try {
        const DATA = {
          email: decodedToken.email,
        };

        const response = await fetch(
          `${config.BACKENDPATH}/users/getworkshops`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(DATA),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
console.log(data);
        setWorkshops(data);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching workshops:", error);
      }
    }
  };

  useEffect(() => {
    // Decode the token from local storage
    gettoken();
  }, []);

  //State to handle the mode of the participant i.e. CONFERNCE or VIEWER
  const [mode, setMode] = useState("CONFERENCE");

  //Getting MeetingId from the API we created earlier
  const getMeetingAndToken = async (id, eventId) => {
    const meetId = id == null ? await createMeeting({ token: authToken }) : id;
    setMeetingId(meetId);

    const DATA = {
      eventId: eventId,
      meetingId: meetId,
    };

    const response = await fetch(`${config.BACKENDPATH}/users/updatemeeting`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(DATA),
    });
window.location.reload();
    setLoading(false);
  };

  return (
    <div className="schedule">
      <Dashnav />
      <div className="dashboard_flex_hor">
        {onlyWidth >= 720 && <Sidenavt />};
        {(loading || loading2)?<Loader/>: <div className="sch_workshop">
          <div className="sch_sett">
            <div className="sch_workshop_txt">Schedule</div>
            {/* <div className="sch_empty_btn">
                <BsPlusSquare className="sch_empty_icon" />
                <div className="sch_empty_btn_txt">Schedule Workshop</div>
              </div>{" "} */}
          </div>
          {/* <div className="sch_work_empty">
                <img src={img} className="sch_empty_img"/>
                <div className="sch_empty_txt">Looks like you haven't scheduled anything yet !</div>
                <div className="sch_empty_btn">
                    <BsPlusSquare className="sch_empty_icon"/>
                    <div className="sch_empty_btn_txt">Schedule Workshop</div>
                </div>
            </div> */}
         <div className="c_e_flex">
            {workshops.data.workshops.map((e) => {
              return (
                new Date(`${e.date} ${e.duration}`) >= new Date() && (
                  <div
                    key={e.id}
                    className="c_e_flex_v"
                    style={{ textAlign: "left", marginTop: "30px" }}
                  >
                    <img src={e.image} className="event_img" />

                    <div className="cards_info">
                      <div className="cards_info_h1">{e.title}</div>
                      <div className="cards_info_h2">
                        Creator - {workshops.data.name}
                      </div>
                      <div
                        className="cards_info_h1"
                        style={{ marginTop: "20px" }}
                      >
                        Workshop starts in
                      </div>
                      <div className="work_card_flex">
                        <div className="cards_info_h3">
                          <Countdown date={new Date(`${e.date} ${e.time}`)} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "20px",
                            marginTop: "5px",
                          }}
                        >
                          <span className="cards_btn_work">
                            {meetingId || e.meetingId ? (
                              <a
                                href={`/workshop?meetingId=${e.meetingId}`}
                                target={"_blank"}
                                style={{textDecoration:"none",color:"white",fontWeight:"bold"}}
                              >
                                Join
                              </a>
                            ) : (
                              <JoinScreen
                                getMeetingAndToken={getMeetingAndToken}
                                setMode={setMode}
                                eventId={e.id}
                                startTime={new Date(`${e.date} ${e.time}`)}
                              />
                            )}
                          </span>{" "}
                          <span>
                            <BsThreeDotsVertical className="sch_work_dots" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
            ;
        </div>
          </div>}
      </div>
      {onlyWidth < 720 && <Bottomnav />};
    </div>
  );
}

export default Schedule;
