// App.js
import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import Signin from './components/login/Login';
import "./App.css"
import Signup from './components/signup/Signup';
import Create from './components/dashboard/createprofile/Create';
import Dashboard from './components/dashboard/creator/Dashboard';
import Analytics from './components/dashboard/analytics/Analytics';
import Profile from './components/dashboard/profile/Profile';
import Schedule from './components/dashboard/schedule/Schedule';
import Creator from './components/screen/Creator'; 
import Video from './components/videosdk/Video';
import Scheduleworkshop from './components/dashboard/schedule/Scheduleworkshop';
import Host from './components/videosdk/Host';
import Viewer from './components/videosdk/Viewer';

function App() {
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  // Hardcoded password for demonstration purposes
  const correctPassword = 'a';

  // Function to handle password input change
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if the entered password matches the correct password
    if (password === correctPassword) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  };

  return (

    <div className='App'>
      {/* {!authenticated?
        <form onSubmit={handleSubmit} className="form_auth">
          
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className="auth_pass"
              placeholder='encrypted...'
            />

          <button type="submit" className='auth_btn'>Submit</button>
        </form>: <Router> */}
         <Router> 
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/signin" element={<Signin/>} />
        <Route exact path="/signup" element={<Signup/>} />
        <Route exact path="/user" element={<Creator/>} />
        <Route exact path="/profilecreate" element={<Create/>} />
        <Route exact path="/workshop" element={<Host/>} />
        <Route exact path="/workshopViewMode" element={<Viewer/>} />
        <Route exact path="/profile" element={<Profile/>} />
        <Route exact path="/analytics" element={<Analytics/>} />
        <Route exact path="/dashboard" element={<Dashboard/>} />
        <Route exact path="/scheduleWorkshop" element={<Scheduleworkshop/>} />
        <Route exact path="/schedule" element={<Schedule/>} />
      </Routes>
    </Router>
   

    
    </div>
  );
}

export default App;
