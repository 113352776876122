import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Dashnav from "../../navbar/Dashnav";
import Sidenav from "../../navbar/Sidenav";
import Sidenavt from "../../navbar/Sidenavt";
import "./Dashboard.css";
import { IoStatsChart } from "react-icons/io5";
import { useWindowWidth } from '@react-hook/window-size';
import Bottomnav from "../../navbar/Bottomnav";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Loader from "../../loader/Loader";
import config from "../../APIs/Cred";

function Dashboard() {
  const [followers, setFollowers] = useState(0);
  const [workshops, setWorkshops] = useState([]);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);
  const [load, setData] = useState(false);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalUser, settotalUser] = useState(0);
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const onlyWidth = useWindowWidth();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const email = decodedToken.email;

      // Send POST request with email
      axios.post(`${config.BACKENDPATH}/users/getDashboardData`, { email })
        .then(response => {
          // Assuming response.data contains the necessary data
          
          const userData = response.data;
          setFollowers(userData.followers);
          setWorkshops(userData.wData);
          setSelectedWorkshop(userData.wData.length > 0 ? userData.wData[0] : null);
          setProfiles(userData.profiles);
          
          // Calculate total earnings
          const totalEarnings = userData.wData.reduce((total, workshop) => total + workshop.earning, 0);
          const totaluser = userData.wData.reduce((total, workshop) => total + workshop.user, 0);
          setTotalEarnings(totalEarnings);
          setCurrentEarnings(totalEarnings); // Initialize with total earnings
          settotalUser(totaluser);
          setData(true);
        })
        .catch(error => {
          console.error("Error fetching user data:", error);
        });
    }
  }, []);

  const handleWorkshopChange = (event) => {
    const selectedTitle = event.target.value;
    const workshop = workshops.find(w => w.title === selectedTitle);
    setSelectedWorkshop(workshop);
    setCurrentEarnings(workshop ? workshop.earning : totalEarnings);
  };

  return (
    <div className="dashboard">
      <Dashnav />
      <div className="dashboard_flex_hor">
        {onlyWidth >= 720 && <Sidenavt />}
        {!load?<Loader/>:<div className="dashboard_flex_hor_a">
          <div className="dashboard_right">
            <div className="summary">
              <div className="summ_txt">Summary</div>
              <div className="summ_dropdown">
                <select
                  name="summ_dropdown_eles"
                  id="summ_dropdown_eles"
                  className="summ_dropdown_eles"
                  onChange={handleWorkshopChange}
                >
                  <option value="all">All Workshops</option>
                  {workshops.map((workshop, index) => (
                    <option key={index} value={workshop.title}>
                      {workshop.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="followers">New Followers</div>
            <div className="followers_hwrap">
              <div className="line_f"></div>
              <div className="count_f">{followers}</div>
            </div>
            <div className="wreg_hwrap">
              <div className="wreg">Workshop Registration</div>
              <div className="wreg_f"></div>
              <div className="wreg_c_f">{selectedWorkshop ? selectedWorkshop.user : totalUser}</div>
            </div>
            <div className="summ_txt" style={{ textAlign: "left", marginTop: "50px" }}>
              Most attended workshop
            </div>
            {workshops.length > 0 ? (
              <div style={{ width: '100%', height: '400px', marginTop: '50px' }}>
                <ResponsiveContainer>
                  <BarChart
                    width={500}
                    height={300}
                    data={workshops}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="title" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="user" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="summ_txt" style={{ textAlign: "center", marginLeft: "80px", marginTop: "100px", fontSize: "20px" }}>
                No data available
              </div>
            )}
          </div>
          <div className="dashboard_left">
            <div className="summary">
              <div className="summ_txt">Earnings</div>
             
            </div>
            <div className="earnings">
              <div className="e_l">
                <div className="e_l_txt">Rs {currentEarnings}</div>
                <div className="followers_e_hwrap">
                  <div className="line_e_f"></div>
                  <div className="count_e_f"></div>
                </div>
              </div>
              <div className="e_r">
                <IoStatsChart className="stats" />
                <div className="e_r_txt">Explore</div>
              </div>
            </div>
            <div className="summ_txt" style={{ textAlign: "left", marginTop: "50px" }}>
              Creator's Leaderboard
            </div>
            {profiles.length > 0 ? (
              <div className="profiles-table" style={{ marginTop: "20px" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr style={{ borderBottom: "1px solid #ffffff", textAlign:"center" }}>
                      <th style={{ border: "1px solid #ffffff", padding: "10px", textAlign: "center", fontWeight: "bold", color: "#ffffff" }}>Username</th>
                      <th style={{ border: "1px solid #ffffff", padding: "10px", textAlign: "center", fontWeight: "bold", color: "#ffffff" }}>Followers</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profiles.map((profile, index) => (
                      <tr key={index} style={{ borderBottom: "1px solid #ffffff" }}>
                        <td style={{ border: "1px solid #ffffff", padding: "10px", color: "#ffffff" }}>{profile.username}</td>
                        <td style={{ border: "1px solid #ffffff", padding: "10px", color: "#ffffff" }}>{profile.followers}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="summ_txt" style={{ textAlign: "center", marginLeft: "80px", marginTop: "100px", fontSize: "20px" }}>
              No data available
            </div>
            )}
          </div>
        </div>}
      </div>
      {onlyWidth < 720 && <Bottomnav />}
    </div>
  );
}

export default Dashboard;
