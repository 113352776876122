import React, { useState, useEffect } from "react";
import "./Signup.css";
import logo from "../images/logo_t.png";
import validator from "validator";
import { Link } from "react-router-dom";
import Loader from "../loader/Loader";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { baseUrl } from "../../constants/backendURL";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Country, State, City } from "country-state-city";

function Signup() {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contact: "",
    countryCode: "",
    password: "",
    confirmPassword: "",
    country: "",
    state: "",
    workshops: [],
    userId: "",
    agreeToTerms: false,
  });

  const [emailError, setEmailError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setFormData({ ...formData, country: selectedCountry });

    const allStates = State.getStatesOfCountry(selectedCountry);
    setStates(allStates);
    setCities([]);
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setFormData({ ...formData, state: selectedState });

    const allCities = City.getCitiesOfState(formData.country, selectedState);
    setCities(allCities);
  };

  const footerContent = (
    <Button
      label="Ok"
      icon="pi pi-check"
      style={{ backgroundColor: "black", color: "white", border: "none" }}
      onClick={() => setVisible(false)}
      autoFocus
    />
  );

  const showDialog = (message) => {
    setContent(message);
    setVisible(true);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateEmail = () => {
    if (!validator.isEmail(formData.email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const checkPasswordStrength = () => {
    const { password, confirmPassword } = formData;

    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setPasswordStrength("Passwords do not match.");
      } else if (
        password.length < 6 ||
        !/[!@#$%^&*(),.?":{}|<>0-9]/.test(password)
      ) {
        setPasswordStrength(
          "Password should be at least 6 characters long with special characters and numbers."
        );
      } else {
        setPasswordStrength("");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateEmail();
    checkPasswordStrength();

    if (emailError || passwordStrength || !formData.agreeToTerms) {
      showDialog("Please correct the errors in the form.");
      return;
    }

    const isFormValid = Object.values(formData).every(
      (value) => value || value === false
    );
    if (!isFormValid) {
      showDialog("Please fill all the details.");
      return;
    }

    setLoading(true);
    fetch(`${baseUrl}users/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(() => {
        setLoading(false);
        showDialog("Thanks for registering, you are added to the queue.");
        setFormSubmitted(true);
      })
      .catch((error) => {
        setLoading(false);
        showDialog(`Error: ${error.message}`);
      });
  };

  if (loading) {
    return <Loader />;
  }
  console.log(formData)

  return (
    <>
      <div
        style={{
          position: "absolute",
          color: "white",
          top: "20px",
          left: "20px",
          fontSize: "20px",
          fontWeight: "bolder",
          display: "flex",
        }}
      >
        <IoReturnUpBackOutline />
        <Link
          to="/"
          style={{ textDecoration: "none", color: "white", marginLeft: "10px" }}
        >
          Home
        </Link>
      </div>
      <Dialog
        header={<img src={logo} alt="Logo" />}
        visible={visible}
        position="top"
        style={{ width: "320px" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
        draggable={false}
        resizable={false}
      >
        <p className="m-0">{content}</p>
      </Dialog>
      <div className="signup_bg">
        <div className="signup">
          <img src={logo} className="signup_img" alt="Logo" />
          <div className="signup_txt1">Create Account</div>
          <form onSubmit={handleSubmit}>
            <div className="signup_ip_wrap">
              <div className="signup_ip">
                <div className="signup_ip_text">Full Name</div>
                <input
                  type="text"
                  name="fullName"
                  className="signup_ip_box"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="signup_ip_wrap">
              <div className="signup_ip">
                <div className="signup_ip_text">Email</div>
                <input
                  type="email"
                  name="email"
                  className="signup_ip_box"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={validateEmail}
                  required
                />
                <div className="error-message">{emailError}</div>
              </div>
            </div>

            <div className="signup_ip_wrap">
              <div className="signup_ip">
                <div className="signup_ip_text">Phone Number</div>
                <PhoneInput
                  international
                  defaultCountry="IN"
                  value={formData.countryCode}
                  onChange={(value) =>
                    setFormData({ ...formData, countryCode: value })
                  }
                  className="signup_ip_box"
                  required
                />
              </div>
            </div>

            <div className="signup_ip_wrap">
              <div className="signup_ip">
                <div className="signup_ip_text">Password</div>
                <input
                  type="password"
                  name="password"
                  className="signup_ip_box"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="signup_ip_wrap">
              <div className="signup_ip">
                <div className="signup_ip_text">Confirm Password</div>
                <input
                  type="password"
                  name="confirmPassword"
                  className="signup_ip_box"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  onBlur={checkPasswordStrength}
                  required
                />
                <div className="error-message">{passwordStrength}</div>
              </div>
            </div>

            <div className="hor_wrap_signup">
              <div className="signup_ip_wrap">
                <div className="signup_ip">
                  <div className="signup_ip_text">Country</div>
                  <select
                    name="country"
                    className="signup_ip_box1"
                    value={formData.country}
                    onChange={handleCountryChange}
                    required
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="signup_ip_wrap">
                <div className="signup_ip">
                  <div className="signup_ip_text">State</div>
                  <select
                    name="state"
                    className="signup_ip_box1"
                    value={formData.state}
                    onChange={handleStateChange}
                    required
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* <div className="signup_ip_wrap">
                <div className="signup_ip">
                  <div className="signup_ip_text">City</div>
                  <select
                    name="city"
                    className="signup_ip_box1"
                    value={formData.city}
                    onChange={(e) =>
                      setFormData({ ...formData, city: e.target.value })
                    }
                    required
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option key={city.isoCode} value={city.isoCode}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div> */}
            </div>

            <div className="check_ip">
              <input
                type="checkbox"
                name="agreeToTerms"
                className="checkbox_signup"
                checked={formData.agreeToTerms}
                onChange={handleInputChange}
                required
              />
              <div className="check_ip_txt">
                I Agree to Terms and Conditions
              </div>
            </div>

            <button
              type="submit"
              className="signup_btn"
              style={{ cursor: "pointer" }}
            >
              Sign Up
            </button>

            {formSubmitted && (
              <div className="form-submitted-message">
                Form submitted successfully!
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default Signup;
 