import React, { useEffect, useState } from "react";
import { BiHomeAlt2 } from "react-icons/bi";
import { CiCompass1 } from "react-icons/ci";
import { FiSettings } from "react-icons/fi";
import { IoCloudUploadSharp } from "react-icons/io5";
import { AiOutlineLock } from "react-icons/ai";
import { AiFillSchedule } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { jwtDecode } from "jwt-decode";

import "./Sidenav.css";
function Bottomnav(props) {
  const [valid, setValid] = useState(false);
  useEffect(() => {
    // Decode the token from local storage
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);

      // Set email from the decoded token

      // Fetch user data based on the decoded token's email
      fetchUserData(decodedToken.email);
    }
  }, []);

  const fetchUserData = (email) => {
    // Simulate fetching user data from the backend
    // Replace this with your actual API call to retrieve user data based on email
    fetch(`https://impresiot-backend.onrender.com/users/findProfile?email=${email}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.user.fullName.length > 0) {
          setValid(() => true);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  return (
    <div className="bottomnav">
      <div
        className="bottomnav_ele"
        style={{ cursor: valid ? "pointer" : "default" }}
      >
        <BiHomeAlt2 className="icon_sidenav" />

        <div className="sidenav_txt">Dashboard </div>
        {!valid && <AiOutlineLock className="lock_icon" />}
      </div>
      <div
        className="bottomnav_ele"
        style={{ cursor: valid ? "pointer" : "default" }}
      >
        <CiCompass1 className="icon_sidenav" />

        <div className="sidenav_txt">Analytics</div>
        {!valid && <AiOutlineLock className="lock_icon" />}
      </div>
      <div
        className="bottomnav_ele"
        style={{ cursor: valid ? "pointer" : "default" }}
      >
        <IoCloudUploadSharp className="icon_sidenav" />

        <div className="sidenav_txt">Uploads</div>
        {!valid && <AiOutlineLock className="lock_icon" />}
      </div>
      <div
        className="bottomnav_ele"
        style={{ cursor: valid ? "pointer" : "default" }}
      >
        <AiFillSchedule className="icon_sidenav" />

        <div className="sidenav_txt">Schedule</div>
        {!valid && <AiOutlineLock className="lock_icon" />}
      </div>
      <div className="bottomnav_ele" style={{ cursor: "pointer" }}>
        <CgProfile className="icon_sidenav" />

        <div className="sidenav_txt">Profile</div>
      </div>
      <div
        className="bottomnav_ele"
        style={{ cursor: valid ? "pointer" : "default" }}
      >
        <FiSettings className="icon_sidenav" />

        <div className="sidenav_txt">Settings</div>
        {!valid && <AiOutlineLock className="lock_icon" />}
      </div>
      <div className="bottomnav_btn">Home</div>
    </div>
  );
}

export default Bottomnav;
