// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog_pay{
    /* position: absolute; */
}

.btn_razor{
    background-color: white;
    color: black;
    border-radius: 10px;
    width: 100px;
    text-align: center;
    padding: 10px;
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/payment/Payment.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB","sourcesContent":[".dialog_pay{\n    /* position: absolute; */\n}\n\n.btn_razor{\n    background-color: white;\n    color: black;\n    border-radius: 10px;\n    width: 100px;\n    text-align: center;\n    padding: 10px;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
