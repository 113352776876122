import React, { useState, useEffect } from "react";
import "./Dashnav.css";
import logo from "../images/logo_t.png";
import { AiOutlineSearch } from "react-icons/ai";
import { BsCameraVideo } from "react-icons/bs";
import { MdNotificationsActive } from "react-icons/md";
import { useWindowWidth } from "@react-hook/window-size";
import { jwtDecode } from "jwt-decode"; // Remove curly braces
import { Link } from "react-router-dom";
import Workshopmodal from "../dashboard/profile/Workshopmodal";
import { differenceInMinutes } from "date-fns";
import config from "../APIs/Cred";

function Dashnav() {
  const width = useWindowWidth();
  const [userExists, setUserExists] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [workshop, setworkshop] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = jwtDecode(token);
      const email = decodedToken.email;

      fetch("https://impresiot-backend.onrender.com/users/checkUserExists", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      })
        .then((response) => response.json())
        .then((data) => {
          setUserExists(data.exists);
        })
        .catch((error) => {
          console.error("Error checking user existence:", error);
        });
    }
  }, []);

  useEffect(() => {
    fetch(`${config.BACKENDPATH}/users/searchbar`) // Adjust the URL to your backend endpoint
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const results = data.filter((item) =>
        (item.username ? item.username : item.title)
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
      setFilteredItems(results);
    } else {
      setFilteredItems([]);
    }
  }, [searchQuery, data]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleItemClick = (action) => {
    window.open(action, "_blank");
  };

  return (
    <div className="nav_over">
      <div className="dashnav">
        <img src={logo} className="dashnav_logo" alt="Logo" />
        {width > 720 && data && (
          <div className="search_bar">
            <input
              type="text"
              className="search_input"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {filteredItems.length > 0 && (
              <div className="search_dropdown">
                {filteredItems.map((item, index) => (
                  <div
                    key={index}
                    className="search_item"
                    onClick={
                      item.tag === "creator"
                        ? () =>
                            handleItemClick(`/profile?creator=${item.username}`)
                        : () => {
                            setVisible(true);
                            setworkshop(item);
                          }
                    }
                    style={{ color: "white" }}
                  >
                    <div className="s_d_flex">
                      <div className="s_d_item">
                        {item.username ? item.username : item.title}
                      </div>
                      <div
                        className={
                          item.tag === "creator"
                            ? "s_d_item_tag1"
                            : "s_d_item_tag2"
                        }
                      >
                        {item.tag}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <AiOutlineSearch className="search_bar_icon" />
          </div>
        )}

        {workshop && (
          <Workshopmodal
            header={workshop.title}
            visible={visible}
            setVisible={setVisible}
          >
            <div className="modal_w">
              <img className="modal_w_im" src={workshop.image} />
              <Link
                to={`/profile?creator=${workshop.creator}`}
                style={{ textDecoration: "none", color: "white" }}
                target="_blank"
              >
                <div className="modal_w_creator">
                  Creator - {workshop.creator}
                </div>
              </Link>

              <div className="modal_w_flex">
                <div className="modal_w_date">
                  A{" "}
                  {differenceInMinutes(
                    new Date(`${workshop.date} ${workshop.duration}`),
                    new Date(`${workshop.date} ${workshop.time}`)
                  )}{" "}
                  minutes workshop on {workshop.date} at {workshop.time} IST
                </div>

                <div className="modal_w_fee">
                  Rs {parseFloat(workshop.fees) / 100}
                </div>
              </div>
              <div className="modal_w_desc">{workshop.description}</div>
            </div>
          </Workshopmodal>
        )}
        <div className="side_dashnav">
          <>
            {userExists ? (
              <Link to={"/schedule"} style={{ textDecoration: "none" }}>
                <div
                  className="golive"
                  style={
                    userExists
                      ? { color: "white", cursor: "pointer" }
                      : { color: "gray", cursor: "not-allowed" }
                  }
                >
                  <div className="golive_txt">Go Live</div>
                  <BsCameraVideo className="golive_icon" />
                </div>
              </Link>
            ) : (
              <div
                className="golive"
                style={
                  userExists
                    ? { color: "white", cursor: "pointer" }
                    : { color: "gray", cursor: "not-allowed" }
                }
              >
                <div className="golive_txt">Go Live</div>
                <BsCameraVideo className="golive_icon" />
              </div>
            )}
            {userExists ? (
              <Link to={"/profile"} style={{ textDecoration: "none" }}>
                <div
                  className="profile"
                  style={
                    userExists
                      ? { color: "white", cursor: "pointer" }
                      : { color: "gray", cursor: "not-allowed" }
                  }
                >
                  Profile
                </div>
              </Link>
            ) : (
              <div
                className="profile"
                style={
                  userExists
                    ? { color: "white", cursor: "pointer" }
                    : { color: "gray", cursor: "not-allowed" }
                }
              >
                Profile
              </div>
            )}
          </>
        </div>
      </div>
      {width <= 720 && data && (
        <div className="search_bar">
          <input
            type="text"
            className="search_input"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            style={{}}
          />
          <AiOutlineSearch className="search_bar_icon_tag" />

          {filteredItems.length > 0 && (
            <div className="search_dropdown" style={{margin:"10px"}}>
              {filteredItems.map((item, index) => (
                <div
                  key={index}
                  className="search_item"
                  onClick={
                    item.tag === "creator"
                      ? () =>
                          handleItemClick(`/profile?creator=${item.username}`)
                      : () => {
                          setVisible(true);
                          setworkshop(item);
                        }
                  }
                  style={{ color: "white" }}
                >
                  <div className="s_d_flex">
                    <div className="s_d_item">
                      {item.username ? item.username : item.title}
                    </div>
                    <div
                      className={
                        item.tag === "creator"
                          ? "s_d_item_tag1"
                          : "s_d_item_tag2"
                      }
                    >
                      {item.tag}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Dashnav;
