import React, { useState, useEffect } from "react";
import "./Profile.css";
import img from "../../images/creators/creator_1.jpg";
import Dashnav from "../../navbar/Dashnav";
import Sidenavt from "../../navbar/Sidenavt";
import { jwtDecode } from "jwt-decode";

import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
  AiFillYoutube,
} from "react-icons/ai";
import { useWindowWidth } from "@react-hook/window-size";
import event from "../../images/events/event.png";
import Bottomnav from "../../navbar/Bottomnav";
import { Link, useLocation } from "react-router-dom";
import Payment from "../../payment/Payment";
import WorkshopCard from "./WorkshopCard";
import Loader from "../../loader/Loader";
import config from "../../APIs/Cred";
function Profile() {
  const onlyWidth = useWindowWidth();
  const location = useLocation();
  const [valid, setValid] = useState(false);
  const [creatorEmail, setcreatorEmail] = useState(null);
  const [secEmail, setsecEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [follow, setFollow] = useState(null);
  const [Workshops, setWorkshops] = useState(null);
  

  const [userData, setUserData] = useState(null);
  const followCreator = async () => {
    fetch(`${config.BACKENDPATH}users/followCreator`, {
    // fetch("https://impresiot-backend.onrender.com/users/followCreator", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userEmail: secEmail, creatorEmail: creatorEmail }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFollow(true);
        alert("Creator is followed");
      })
      .catch((error) => {
      });
  };

  const workshops = (email) => {
    fetch(`${config.BACKENDPATH}/users/getworkshops`, {
    // fetch("https://impresiot-backend.onrender.com/users/getworkshops", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        setWorkshops(data);
      })
      .catch((error) => {
      });
  };

  const getEmail = async (creatorId) => {
    // fetch("https://impresiot-backend.onrender.com/users/getUserEmail", {
    fetch(`${config.BACKENDPATH}/users/getUserEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: creatorId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setcreatorEmail(data.userEmail);
        fetchUserData(data.userEmail);
      })
      .catch((error) => {
      });
  };
  useEffect(() => {
    // Decode the token from local storage
    const creatorId = new URLSearchParams(location.search).get("creator");
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);

      setsecEmail(decodedToken.email);
    }

    if (creatorId) {
      getEmail(creatorId);
    } else {
      fetchUserData(secEmail);
    }
  }, []);

  const fetchUserData = (email) => {
    // Simulate fetching user data from the backend
    // Replace this with your actual API call to retrieve user data based on email
    const token = jwtDecode(localStorage.getItem("token"));
    const creatorId = new URLSearchParams(location.search).get("creator");

    if (!creatorId) {
      email = token.email;
    }
    fetch(
      `${config.BACKENDPATH}/users/findProfile?email=${email}&userEmail=${token.email}`
      // `https://impresiot-backend.onrender.com/findProfile?email=${email}&userEmail=${token.email}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserData(data.creatorProfile);
        console.log(data);
        setFollow(data.isFollowing);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });

    if (creatorId) {
      workshops(email);
    } else {
      workshops(token.email);
    }
  };

    return (
      <div className="profile">
        <Dashnav />
        <div className="dashboard_flex_hor">
          {onlyWidth >= 720 && <Sidenavt />}
          {(!secEmail || !userData || !Workshops)?<Loader/>: <div className="profile_sup">
            <div className="profile_upper">
              <img src={userData.image} className="prf_pic" />
              <div className="profile_r" >
                <div className="profile_flex_hor">
                  <div className="profile_txt_head">{userData.fullName}</div>
                  {(secEmail == creatorEmail || !creatorEmail) && (
                    <Link
                      to={"/profilecreate"}
                      style={{ textDecoration: "none", color: "gray" }}
                      target="_blank"
                    >
                      <div className="profile_btn">Edit profile</div>
                    </Link>
                  )}
                  {creatorEmail && secEmail != creatorEmail && (
                    <div
                      className="profile_btn"
                      onClick={!follow ? followCreator : ""}
                    >
                      {follow ? "Followed" : "Follow"}
                    </div>
                  )}
                </div>
                <div className="profile_txt_body">Creator</div>
                <div className="profile_txt_body_main">{userData.bio}</div>
              </div>
            </div>
            <div className="profile_dash_head">Links</div>
            <div className="links_hor">
              <a
                href={"https:/" + userData.instagram}
                style={{ textDecoration: "none" }}
                target={"_blank"}
              >
                <div className="links_card">
                  <AiFillInstagram className="links_icon" />
                  <div className="links_card_txt">Instagram</div>
                </div>
              </a>
              <a
                href={"https:/" + userData.facebook}
                style={{ textDecoration: "none" }}
                target={"_blank"}
              >
                <div className="links_card">
                  <AiFillFacebook className="links_icon" />

                  <div className="links_card_txt">Facebook</div>
                </div>
              </a>
              <a
                href={"https:/" + userData.youtube}
                style={{ textDecoration: "none" }}
                target={"_blank"}
              >
                <div className="links_card">
                  <AiFillYoutube className="links_icon" />

                  <div className="links_card_txt">Youtube</div>
                </div>
              </a>
              <a
                href={"https:/" + userData.twitter}
                style={{ textDecoration: "none" }}
                target={"_blank"}
              >
                <div className="links_card">
                  <AiFillTwitterSquare className="links_icon" />

                  <div className="links_card_txt">Twitter</div>
                </div>
              </a>
            </div>
            <div className="profile_dash_head">
              {creatorEmail && secEmail != creatorEmail
                ? userData.username + " Workshops"
                : "Your Workshops"}
            </div>

            <div className="events_lineup" style={{ textAlign: "left" }}>
            {Workshops.data.workshops.map((workshop) => (
                  // <div className="work_cards" key={workshop.id}>
                  //   <img src={event} className="work_card" alt="Event" />
                  //   <div className="work_info">
                  //     <div
                  //       className="cards_info_h1"
                  //       style={{ whiteSpace: "normal" }}
                  //     >
                  //       {workshop.title}
                  //     </div>
                  //     <div className="cards_info_h2">
                  //       Creator -{" "}
                  //       <Link
                  //         to={`/profile?creator=${workshop.creator}`}
                  //         style={{ textDecoration: "none", color: "gray" }}
                  //       >
                  //         {workshop.creator}
                  //       </Link>
                  //     </div>
                  //     <div className="card_work_btn">
                  //       <Payment id={workshop.id} fees={workshop.fees} />
                  //     </div>
                  //   </div>
                  // </div>
                  <WorkshopCard workshop={workshop} mid={"1"}/>
                ))}
             
            </div>
          </div>}
        </div>

        
        {onlyWidth < 720 && <Bottomnav />};
      </div>
    );
  }

export default Profile;
