import React from 'react'
import Navbar from '../navbar/Navbar'
import "./Home.css"
import img from "../images/home_img.png"
function Home() {
  return (
    <div className='home'>
        <Navbar/>
        <div className='boujee-text'>Create Without any Limits</div>
        <div className='sec_text_home'>One stop solution to regulate the</div>
        <div className='sec_text_home'>Creator's Economy</div>
        <img src={img} className='home_img'/>
    </div>
  )
}

export default Home