import React, { useState, useEffect } from "react";
import { BiHomeAlt2 } from "react-icons/bi";
import { CiCompass1 } from "react-icons/ci";
import { VscLibrary } from "react-icons/vsc";
import { FiSettings } from "react-icons/fi";
import "./Sidenav.css";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
function Sidenav(props) {
  const [slct, setslct] = useState("1");

  const handleLogout=()=>{
    localStorage.removeItem("token");
    window.location.href = "/";

  }
  return (
    <div className="sidenav">
      <a
        href="#home"
   
        style={
          slct === "1"
            ? { textDecoration: "none", color: "white" }
            : { textDecoration: "none",color:"gray" }
        }
      >
        <div className="sidenav_ele" onClick={() => setslct("1")}>
          <BiHomeAlt2 className="icon_sidenav" />

          <div className="sidenav_txt">Home</div>
        </div>
      </a>
      <a
        href="#creators"
     
        style={
          slct === "2"
            ? { textDecoration: "none", color: "white" }
            : { textDecoration: "none",color:"gray" }
        }
      >
        <div className="sidenav_ele" onClick={() => setslct("2")}>
          <CiCompass1 className="icon_sidenav" />

          <div className="sidenav_txt">Creators</div>
        </div>
      </a>
      <a
        href="#workshops"
  
        style={
          slct === "3"
            ? { textDecoration: "none", color: "white" }
            : { textDecoration: "none",color:"gray" }
        }
      >
        <div className="sidenav_ele" onClick={() => setslct("3")}>
        <VscLibrary className="icon_sidenav" />

        <div className="sidenav_txt">Workshops</div>
      </div>
      </a>
      <div className="sidenav_ele" style={{color:"red",cursor:"pointer"}}>
        <FiSettings className="icon_sidenav" />
        <div className="sidenav_txt" onClick={handleLogout}>Logout</div>
      </div>
      <Link className="marginTop" to="/profilecreate" style={{ textDecoration: "none" }} target="_blank">
        <div className="sidenav_btn">Creator Studio</div>       
      </Link>
    </div>
  );
}

export default Sidenav;
