import React from 'react';
import { Dialog } from 'primereact/dialog';

const Workshopmodal = ({ header, visible, setVisible,children }) => {
  const onHide = () => {
    if (!visible) return;
    setVisible(false);
  };

  return (
    <Dialog
      header={header}
      visible={visible}
      style={{ width: "90vw", color: "white" }}
      onHide={onHide}
    >
     {children}
    </Dialog>
  );
};

export default Workshopmodal;
