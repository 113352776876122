import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  format,
  differenceInMilliseconds,
  differenceInMinutes,
} from "date-fns";
import Countdown from "react-countdown";
import "./Profile.css";
import { Dialog } from "primereact/dialog";
import Payment from "../../payment/Payment";
import Workshopmodal from "./Workshopmodal";
import Loader from "../../loader/Loader";
import config from "../../APIs/Cred";
function WorkshopCard({ workshop, email, userData, wid, mid }) {
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [urlE, seturlE] = useState(null);
  const currentTime = new Date();
  const workshopTime = new Date(`${workshop.date} ${workshop.time}`);

  const workshopDuration = new Date(`${workshop.date} ${workshop.duration}`);
  const durationMinutes = differenceInMinutes(workshopDuration, workshopTime);

  const isWorkshopDone = currentTime > workshopDuration;
  const isWorkshopInProgress =
    currentTime >= workshopTime && currentTime <= workshopDuration; // Assuming workshop has a duration property
 
  const renderStatus = () => {
    if (isWorkshopDone) {
      return (
        <>
          <div className="cards_info_h3" style={{ fontSize: "10px" }}>
            Workshop Done
          </div>
          <div className="card_work_btn">
            {(wid === "Online With Recording" && wid !== "") || mid === "1" ? (
              <button
                onClick={() => handleRecButtonClick(workshop.meetingId)}
                style={{
                  backgroundColor: "transparent",
                  border: "transparent",
                  color: "white",
                  fontWeight: "bolder",
                  cursor:"pointer"
                }}
              >
                Rec
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "transparent",
                  color: "white",
                  fontWeight: "bolder",
                  
                }}
              >
                Ended
              </button>
            )}
          </div>
        </>
      );
    } else if (isWorkshopInProgress) {
      return (
        <>
          <div className="cards_info_h3" style={{fontSize:"15px"}}>Workshop Ongoing</div>
          <div className="card_work_btn">
            {(wid !== "Offline" && wid !== "") || mid === "1" ? (
              <a
                href={`/workshopViewMode?meetingId=${workshop.meetingId}`}
                style={{
                  backgroundColor: "transparent",
                  border: "transparent",
                  color: "white",
                  fontWeight: "bolder",
                  textDecoration:"none",
                  cursor:workshop.meetingId?"pointer":"not-allowed"
                }}
                target="_blank"
              >
                {workshop.meetingId?"Join":"Wait"}
              </a>
            ) : (
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "transparent",
                  color: "white",
                  fontWeight: "bolder",
                  textDecoration:"none"
                }}
              >
                Started
              </button>
            )}
          </div>
        </>
      );
    } else {
      const timeDiff = differenceInMilliseconds(workshopTime, currentTime);
      return (
        <>
          <div className="cards_info_h3" style={{ fontSize: "10px" }}>
            Workshop will start in{" "}
            <Countdown
              date={workshopTime}
              renderer={({ days, hours, minutes, completed }) => {
                if (completed) {
                  return <span>few seconds</span>;
                } else {
                  return (
                    <span>
                      {days}d {hours}h {minutes}m
                    </span>
                  );
                }
              }}
            />
          </div>
          <div className="card_work_btn">
            <Payment
              id={workshop.id}
              fees={workshop.fees}
              email={email}
              userData={userData}
              mode={workshop.mode}
              wid={wid}
              mid={mid}
            />
          </div>
        </>
      );
    }
  };

  const handleJoinButtonClick = (workshopId) => {
    // Implement the logic for joining the workshop
    console.log(`Join workshop with ID: ${workshopId}`);
  };

  const handleRecButtonClick = async (roomId) => {
    setVisible1(true);
    try {
      // Make a fetch request to your backend endpoint
      const response = await fetch(`${config.BACKENDPATH}/users/recFetch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ roomId }), // Assuming you send workshopId to identify the recording
      });

      if (!response.ok) {
        throw new Error("Failed to fetch recording data");
      }

      const data = await response.json();
      console.log(data);

      // Open a new window with the fileUrl
      if (data.fileUrl) {
seturlE(data.fileUrl);


} 
    } catch (error) {
      console.error("Error fetching recording data:", error);
      // Handle error, e.g., show error message to user
    }
  };
  return (
    <div
      className="work_cards"
      key={workshop.id}
      style={{ marginRight: "30px", marginTop: "30px" }}
    >
      <img
        src={workshop.image}
        className="work_card"
        alt="Event"
        onClick={() => setVisible(true)}
                style={{ cursor: "pointer" }}
      />
      <div className="work_info">
        <div className="cards_info_h1" style={{ whiteSpace: "normal" }}>
          {workshop.title}
        </div>
        <Workshopmodal
          header={workshop.title}
          visible={visible1}
          setVisible={setVisible1}
        >
{urlE?<video controls style={{width:"100%"}}>        <source src={urlE} type="video/mp4"/>
</video>:<Loader/>}

        </Workshopmodal>
        <Workshopmodal
          header={workshop.title}
          visible={visible}
          setVisible={setVisible}
        >
          <div className="modal_w">
            <img className="modal_w_im" src={workshop.image} />
            <Link
            to={`/profile?creator=${workshop.creator}`}
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
          >

            <div className="modal_w_creator">Creator - {" "}{workshop.creator}</div>
          </Link>

            <div className="modal_w_flex">
              <div className="modal_w_date">
                A {durationMinutes} minutes workshop on {workshop.date} at{" "}
                {workshop.time} IST
              </div>

              <div className="modal_w_fee">
                Rs {parseFloat(workshop.fees) / 100}
              </div>
            </div>
            <div className="modal_w_desc">{workshop.description}</div>
          </div>
        </Workshopmodal>

        <div className="cards_info_h2">
          Creator -{" "}
          <Link
            to={`/profile?creator=${workshop.creator}`}
            style={{ textDecoration: "none", color: "gray" }}
            target="_blank"
          >
            {workshop.creator}
          </Link>
        </div>
        {renderStatus()}
      </div>
    </div>
  );
}

export default WorkshopCard;
