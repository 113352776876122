//Auth token we will use to generate a meeting and connect to it
export const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiI2Yjc5MWQ1NC00ODc1LTQ0ZjctYjYwYS0xODY3MDRhNTNmNGUiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcyMDk4NjUxOCwiZXhwIjoxNzIxNTkxMzE4fQ.hvE1eVXBvOTEOnGlrxydtw6fvWc_DmczzKnTMF7gpn8";
// API call to create meeting
export const createMeeting = async ({ token }) => {
  const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
    method: "POST",
    headers: {
      authorization: `${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  //Destructuring the roomId from the response
  const { roomId } = await res.json();
  return roomId;
};

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))


export const startRecordingFunc = async (roomId) => {
  const options = {
    method: 'POST',
    headers: {
      'Authorization': authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'roomId': roomId,
      'awsDirPath': 'http://s3.amazonaws.com/impresiotrec',
      
    }),
  };

  try {
    const url = 'https://api.videosdk.live/v2/recordings/start';
    const response = await fetch(url, options);
    const data = await response.json();
    console.log(data);
    await sleep(10000);
    
    return 1;
    // Do something with the data, e.g., update state or perform other actions
  } catch (error) {
    return 0;
    // Handle error as needed
  }
};
export const endRecordingFunc = async (roomId) => {
  const options = {
    method: 'POST',
    headers: {
      'Authorization': authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'roomId': roomId,
      'awsDirPath': 'http://s3.amazonaws.com/impresiotrec',
    }),
  };

  try {
    const url = 'https://api.videosdk.live/v2/recordings/end';
    const response = await fetch(url, options);
    const data = await response.json();
    console.log(data);
    return 1;
    // Do something with the data, e.g., update state or perform other actions
  } catch (error) {
    return 0;
    // Handle error as needed
  }
};