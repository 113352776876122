// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidenav_ele {
  display: flex;
  align-items: center;
  margin-top: 50px;
}
.bottomnav_ele {
  display: flex;
  align-items: center;
  margin-top: 50px;
  width: 160px;
  justify-content: center;
}
.sidenav_txt {
  font-size: 15px;
  margin-left: 10px;
  font-weight: bolder;
}
.icon_sidenav {
  font-size: 20px;
}
.sidenav {
  background-color: black;
  width: 200px;
  height: 100vh;
  color: #494949;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 1px solid white; */
  margin-left: 30px;
}

.marginTop{
    margin-top: 100px;
}

.bottomnav {
  background-color: black;
  flex-wrap: wrap;
  color: #494949;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.sidenav_btn {
  background-color: #ff3a3a;
  color: white;
  padding: 10px;
  border-radius: 10px;
  font-weight: bolder;
}

.bottomnav_btn {
  background-color: #ff3a3a;
  color: white;
  padding: 10px;
  border-radius: 10px;
  width: 160px;
  margin-top: 30px;
  font-weight: bolder;
}

.lock_icon {
  font-size: 20px;
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar/Sidenav.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,6BAA6B;EAC7B,iBAAiB;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,cAAc;EACd,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".sidenav_ele {\n  display: flex;\n  align-items: center;\n  margin-top: 50px;\n}\n.bottomnav_ele {\n  display: flex;\n  align-items: center;\n  margin-top: 50px;\n  width: 160px;\n  justify-content: center;\n}\n.sidenav_txt {\n  font-size: 15px;\n  margin-left: 10px;\n  font-weight: bolder;\n}\n.icon_sidenav {\n  font-size: 20px;\n}\n.sidenav {\n  background-color: black;\n  width: 200px;\n  height: 100vh;\n  color: #494949;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  /* border: 1px solid white; */\n  margin-left: 30px;\n}\n\n.marginTop{\n    margin-top: 100px;\n}\n\n.bottomnav {\n  background-color: black;\n  flex-wrap: wrap;\n  color: #494949;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n.sidenav_btn {\n  background-color: #ff3a3a;\n  color: white;\n  padding: 10px;\n  border-radius: 10px;\n  font-weight: bolder;\n}\n\n.bottomnav_btn {\n  background-color: #ff3a3a;\n  color: white;\n  padding: 10px;\n  border-radius: 10px;\n  width: 160px;\n  margin-top: 30px;\n  font-weight: bolder;\n}\n\n.lock_icon {\n  font-size: 20px;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
