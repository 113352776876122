import React from 'react'
import { Audio } from "react-loader-spinner";
import logo from "../images/logo_t.png"
function Loader() {
  return (
    <>
    {/* <img src={logo} style={{position:"absolute",left:"10px",top:"10px"}}/> */}
    <div style={{height:"100vh",width:"100vw",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
         <Audio
                  height="80"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle
                  wrapperClass
                  />
                <div style={{color:"white",letterSpacing:"5px",marginTop:"30px"}}>LOADING THE RESULTS</div>
    </div>
                  </>
  )
}

export default Loader