import React, { useState, useEffect } from "react";
import Dashnav from "../navbar/Dashnav";
import Sidenav from "../navbar/Sidenav";
import "./Creator.css";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";

import Sidenavalt from "../navbar/Sidenavalt";

import { Audio } from "react-loader-spinner";
import WorkshopCard from "../dashboard/profile/WorkshopCard";
import Loader from "../loader/Loader";

import { FaTwitter, FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";
import config from "../APIs/Cred";

const SocialIcons = ({ profile }) => (
  <div className="social_icons">
  
      <a href={profile.twitter} target="_blank" rel="noopener noreferrer">
        <FaTwitter className="social_icon" />
      </a>
  
   
      <a href={profile.instagram} target="_blank" rel="noopener noreferrer">
        <FaInstagram className="social_icon" />
      </a>


      <a href={profile.facebook} target="_blank" rel="noopener noreferrer">
        <FaFacebook className="social_icon" />
      </a>
   
   
      <a href={profile.youtube} target="_blank" rel="noopener noreferrer">
        <FaYoutube className="social_icon" />
      </a>
    
  </div>
);

function Creator() {
  const [selectedOption, setSelectedOption] = useState("Latest");
  const [workshops, setWorkshops] = useState(null);
  const [creators, setCreators] = useState(null);
  const [profiles, setprofiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [EMAIL, setEmail] = useState(null);
  const [userData, setuserData] = useState(null);
  const options = ["Latest", "Registered", "Older"];
  const onlyWidth = useWindowWidth();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);

      // Fetch data from the API
      fetch(`${config.BACKENDPATH}/users/home`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: decodedToken.email }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setprofiles(data.approvedProfiles);
          setCreators(data.followedCreators);
          setuserData(data.user);
          setWorkshops({
            workshopsByDate: data.filteredWorkshopsByDate,
            workshopsReversed: data.filteredWorkshopsReversed,
            userWorkshops: data.userWorkshops,
          });
        })
        .catch((error) => {
          console.error("Error fetching workshops:", error);
        });

      setEmail(decodedToken.email);
    }
  }, []);

  // Event handler for when the dropdown value changes
  const handleDropdownChange = (event) => {
    setLoading(true);
    setSelectedOption(event.target.value);
    setLoading(false);
  };

  return (
    <>
      <Dashnav />
      <div className="creator_dash">
        {onlyWidth >= 720 && <Sidenav />}
        {!workshops || !creators || !EMAIL || !userData || !profiles ? (
          <Loader />
        ) : (
          <div className="creators_follow" id="home">
            <div className="creators_follow_txt">
              Creators{" "}
              <span className="creators_follow_txt_yf">you Follow</span>
            </div>
            <div className="c_f_imgs">
              {creators.map((creator) => (
                <Link
                  to={`/profile?creator=${creator.username}`}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <div className="c_f_flex">
                    <img
                      src={creator.image}
                      className="c_f_img"
                      alt={creator.username}
                    />
                    <div className="c_f_txt">{creator.username}</div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="creators_follow_txt" style={{ marginTop: "30px" }}>
              Upcoming <span className="creators_follow_txt_yf">Events</span>
            </div>
            <div className="events_lineup">
            
              <div className="nda">No Data Available</div>
            </div>
            <div
              className="creators_follow_txt"
              style={{
                marginTop: "30px",
                display: "flex",
                alignItems: "center",
              }}
              id="creators"
            >
              <div>
                Meet{" "}
                <span className="creators_follow_txt_yf">the creators</span>
              </div>
            </div>

            <div className="work_lineup">
              {profiles.map((profile) => (
               <div className="work_cards" key={profile.userId} style={{ marginRight: "30px", marginTop: "30px" }}>
               <img src={profile.image} className="creator_prof_image" alt="Profile" />
               <div className="work_info">
                 <div className="cards_info_h1" style={{ whiteSpace: "normal" }}>
                   {profile.fullName}
                 </div>
                 <div className="cards_info_h2">
                   Explore -{" "}
                   <Link to={`/profile?creator=${profile.username}`} style={{ textDecoration: "none", color: "gray" }}>
                     {profile.username}
                   </Link>
                 </div>
                 <SocialIcons profile={profile} />
               </div>
             </div>
              ))}
            </div>

            <div
              className="creators_follow_txt"
              style={{
                marginTop: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div id="workshops">
                Upcoming{" "}
                <span className="creators_follow_txt_yf">
                  Workshops For You
                </span>
              </div>
              {/* Dropdown (select) element */}
              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleDropdownChange}
                className="filter_wShops"
              >
                {/* Map over options to create dropdown options */}
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              {/* Display the selected option */}
            </div>
            {!loading ? (
              <div className="work_lineup">
                {selectedOption === "Latest" &&
                  workshops.workshopsByDate.map((workshop) => (
                    <WorkshopCard
                      workshop={workshop}
                      email={EMAIL}
                      userData={userData}
                      wid={""}
                      mid={"0"}
                    />
                  ))}
                {selectedOption === "Registered" &&
                  workshops.userWorkshops.map((workshop) => (
                    <WorkshopCard
                      workshop={workshop.workshop}
                      email={EMAIL}
                      userData={userData}
                      wid={workshop.wid}
                      mid={"0"}
                    />
                  ))}
                {selectedOption === "Older" &&
                  workshops.workshopsReversed.map((workshop) => (
                    <WorkshopCard
                      workshop={workshop}
                      email={EMAIL}
                      userData={userData}
                      wid={""}
                      mid={"0"}
                    />
                  ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Audio
                  height="80"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle
                  wrapperClass
                />
              </div>
            )}
          </div>
        )}
      </div>
      {onlyWidth < 720 && <Sidenavalt />};
    </>
  );
}

export default Creator;
