import React, { useEffect, useState } from "react";
import Dashnav from "../../navbar/Dashnav";
import Sidenav from "../../navbar/Sidenav";
import Sidenavt from "../../navbar/Sidenavt";
import Bottomnav from "../../navbar/Bottomnav";
import { IoStatsChart } from "react-icons/io5";
import { US, IN, SN, SA, CH } from "country-flag-icons/react/3x2";
import img from "../../images/events/event.png";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { useWindowWidth } from "@react-hook/window-size";
import "./Analytics.css";
import Loader from "../../loader/Loader";
import config from "../../APIs/Cred";
const Analytics = () => {
  const onlyWidth = useWindowWidth();
  const [analysisData, setAnalysisData] = useState(null);
  const [selectedMilestone, setSelectedMilestone] = useState("all"); // Default selection

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${config.BACKENDPATH}/users/getAnalysis`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: "pranav.chauhan.ug20@nsut.ac.in" }), // replace with actual email
          }
        );
        const data = await response.json();
        setAnalysisData(data);
      } catch (error) {
        console.error("Error fetching analysis data:", error);
      }
    };

    fetchData();
  }, []);

  if (!analysisData) {
    return <Loader/>;
  }

  const {
    totalWorkshops,
    totalDuration,
    lastMonth,
    lastThreeMonths,
    lastSixMonths,
    lastYear,
    totalEarnings,
    totalUsers,
    topWorkshops,
  } = analysisData;

  const handleMilestoneChange = (event) => {
    setSelectedMilestone(event.target.value);
  };

  const renderWorkshopsData = () => {
    if (selectedMilestone === "all") {
      return (
        <div className="analy_flex">
          <div className="analy_circle">
            <div className="analy_txt">Workshops Completed</div>
            <div className="analy_border">
              <div className="analy_ccl">{totalWorkshops}</div>
            </div>
          </div>
          <div className="analy_circle">
            <div className="analy_txt">Hours Dedicated</div>
            <div className="analy_border_2">
              <div className="analy_ccl_2">
                {parseFloat((totalDuration / 60).toFixed(1))}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      const milestoneData = {
        lastMonth: lastMonth,
        lastThreeMonths: lastThreeMonths,
        lastSixMonths: lastSixMonths,
        lastYear: lastYear,
      };
      const selectedData = milestoneData[selectedMilestone];
      const sum = selectedData.duration.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      return (
        <div className="analy_flex">
          <div className="analy_circle">
            <div className="analy_txt">Workshops Completed</div>
            <div className="analy_border">
              <div className="analy_ccl">{selectedData.titles.length}</div>
            </div>
          </div>
          <div className="analy_circle">
            <div className="analy_txt">Hours Dedicated</div>
            <div className="analy_border_2">
              <div className="analy_ccl_2">{(sum / 60).toFixed(1)}</div>
            </div>
          </div>
        </div>
      );
    }
  };
  const renderEarningsData = () => {
    if (selectedMilestone === "all") {
      return (
        <div className="earnings">
          <div className="e_l">
            <div className="e_l_txt">Rs {totalEarnings}</div>
            <div className="followers_e_hwrap">
              <div className="line_e_f"></div>
              <div className="count_e_f">{totalUsers}</div>
            </div>
          </div>
          <div className="e_r">
            <IoStatsChart className="stats" />
            <div className="e_r_txt">No data available</div>
          </div>
        </div>
      );
    } else {
      const milestoneData = {
        lastMonth: lastMonth,
        lastThreeMonths: lastThreeMonths,
        lastSixMonths: lastSixMonths,
        lastYear: lastYear,
      };
      const selectedData = milestoneData[selectedMilestone];
      const earn = selectedData.earnings.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const user = selectedData.users.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      return (
        <div className="earnings">
          <div className="e_l">
            <div className="e_l_txt">Rs {earn}</div>
            <div className="followers_e_hwrap">
              <div className="line_e_f"></div>
              <div className="count_e_f">{user}</div>
            </div>
          </div>
          <div className="e_r">
            <IoStatsChart className="stats" />
            <div className="e_r_txt">No data available</div>
          </div>
        </div>
      );
    }
  };
  const renderGraphData = () => {
    if (selectedMilestone === "all") {
      const data = [
        {
          name: "All Workshops",
          users: totalUsers,
        },
      ];
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data} // This should be replaced with your actual data
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Line type="monotone" dataKey="users" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      );
    } else {
      const milestoneData = {
        lastMonth: lastMonth,
        lastThreeMonths: lastThreeMonths,
        lastSixMonths: lastSixMonths,
        lastYear: lastYear,
      };
      const selectedData = milestoneData[selectedMilestone];

      let data = [];
      for (let i = 0; i < selectedData.titles.length; i++) {
        const e = selectedData.titles[i];
        data.push({
          name: e,
          users: selectedData.users[i],
        });
      }
      console.log("aaaa", data);
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data} // This should be replaced with your actual data
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Line type="monotone" dataKey="users" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      );
    }
  };
  const renderGraphEarningData = () => {
    if (selectedMilestone === "all") {
      const data = [
        {
          name: "Total Earnings",
          earnings: totalEarnings,
        },
      ];
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data} // This should be replaced with your actual data
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Line type="monotone" dataKey="earnings" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      );
    } else {
      const milestoneData = {
        lastMonth: lastMonth,
        lastThreeMonths: lastThreeMonths,
        lastSixMonths: lastSixMonths,
        lastYear: lastYear,
      };
      const selectedData = milestoneData[selectedMilestone];

      let data = [];
      for (let i = 0; i < selectedData.titles.length; i++) {
        const e = selectedData.titles[i];
        data.push({
          name: e,
          earnings: selectedData.earnings[i],
        });
      }
      console.log("aaaa", data);
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data} // This should be replaced with your actual data
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Line type="monotone" dataKey="earnings" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      );
    }
  };

  return (
    <div className="dashboard">
      <Dashnav />
      <div className="dashboard_flex_hor">
        {onlyWidth >= 720 && <Sidenavt />}
        <div style={{ width: "85%" }}>
          <div className="dashboard_flex_hor_a">
            <div className="dashboard_right">
              <div className="summary">
                <div className="summ_txt" style={{ fontSize: "24px" }}>
                  Personal Milestones
                </div>
                <div className="summ_dropdown analy_drop">
                  <select
                    value={selectedMilestone}
                    onChange={handleMilestoneChange}
                    className="summ_dropdown_eles"
                  >
                    <option value="all">All Workshops</option>
                    <option value="lastMonth">Last Month</option>
                    <option value="lastThreeMonths">Last 3 Months</option>
                    <option value="lastSixMonths">Last 6 Months</option>
                    <option value="lastYear">Last Year</option>
                  </select>
                </div>
              </div>
              <div className="analy_flex">{renderWorkshopsData()}</div>
              {/* Placeholder for Monthly Stats */}
              <div className="summary" style={{ marginTop: "40px" }}>
                <div className="summ_txt">Workshop Stats</div>
              </div>

              <div className="card_c_r">{renderGraphData()}</div>
            </div>
            <div className="dashboard_left">
              {/* Earnings section */}
              <div className="summary">
                <div className="summ_txt">Earnings</div>
              </div>
              {renderEarningsData()}

              {/* Content Reach */}
              <div className="summary" style={{ marginTop: "70px" }}>
                <div className="summ_txt">Earnings Stats</div>
              </div>

              <div className="card_c_r">{renderGraphEarningData()}</div>
            </div>
          </div>
         
          {/* Top Content Section */}
          <div
            className="summary"
            style={{ marginTop: "30px", padding: "30px" }}
          >
            <div className="summ_txt">Top Content</div>
          </div>
          <div className="card_c_r_2">
            <div className="flex_hor hor_sc_sm">
              <div
                className="header_1_c_r_2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div className="header_1_c_r_2_txt">Name</div>
                {topWorkshops.map((e) => {
                  return (
                    <div className="header_1_c_r_2_img">
                      <img src={e.image} className="header_1_c_r_2_im" />

                      <div
                        className="header_1_c_r_2_img_txt"
                        style={{ marginLeft: "20px" }}
                      >
                        {e.title}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="header_1_c_r_2">
                <div className="header_1_c_r_2_txt">Users</div>
                {topWorkshops.map((e) => {
                  return (
                    <div className="header_1_c_r_2_img">
                      <div className="header_1_c_r_2_img_txt">
                        {e.userCount}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="header_1_c_r_2">
                <div className="header_1_c_r_2_txt">Revenue(Rs)</div>
                {topWorkshops.map((e) => {
                  return (
                    <div className="header_1_c_r_2_img">
                      <div className="header_1_c_r_2_img_txt">{e.earnings}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {onlyWidth < 720 && <Bottomnav />}
    </div>
  );
};

export default Analytics;
